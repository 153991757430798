import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Header, HOC, HomeItem } from "../components";
import TabularItem from "../components/_tabularitem";
import { SYMPTOMSDATA } from "../utils/_dataset";

const Symptoms = (props) => {
    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pt-5 pr-4">
                    <div className="row m-0">
                        <h3 className="primary-colored flexfull">Symptoms</h3>
                        <Button className="header-btn">
                            <img src={Icons.WHITEPLUSICON} />
                            <h4>Add New Symptoms</h4>
                        </Button>
                    </div>
                    <div className="pt-3" />

                    <div className="w-100">
                        <TabularItem data={SYMPTOMSDATA} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const SymptomsScreen = HOC(Symptoms);

export default SymptomsScreen;