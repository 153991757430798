import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Icons } from "../assets";

const HomeItem = (props) => {
    const { data, onClick } = props;
    return (
        <button style={{ borderWidth: 0, height: 200, width: '100%', }} onClick={onClick} className="shadow-lg p-4 bg-white rounded mt-4">
            <div className="column m-0" style={{ alignItems: 'center', width: '100%' }}>
                <div className="p-2" style={{ background: '#f7f7f7', borderRadius: 10, width: 60, margin: 'auto' }}><img src={data.image} style={{ width: 30, height: 30, margin: 'auto' }} /></div>
                <br />
                <span className="bold" style={{ fontSize: 20, fontWeight: 'bold' }}>{data.name}</span>
                <br />
                <span style={{ fontSize: 12 }}>{data.description}</span>
            </div>
        </button >
    );
}

export default HomeItem;