import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { THEME } from "../utils/_constant";
// import renderHTML from 'react-render-html';
import _ from 'lodash';


const userStatus = [
    { id: 0, name: 'Pending', value: 0 },
    { id: 1, name: 'Review In Progress', value: 1 },
    { id: 2, name: 'Approved', value: 2 },
    { id: 3, name: 'Suspended', value: 3 },
    { id: 4, name: 'Blocked', value: 4 },
    { id: 5, name: 'Temporary Blocked', value: 5 },
]


const TabularItem = (props) => {
    const { data, dataset, onOpen, header, exceptional = false, exceptionalClick = false, onStatus, onView } = props;
    const [headers, setheaders] = useState(_.isEmpty(header) ? data.head : header);
    useEffect(() => {
        if (data) {
            if (!_.isEmpty(header)) {
                setheaders(header);
            } else {
                setheaders(data.head);
            }

        }
    }, [header]);

    const handleReturnClassName = () => headers.length === 3 ? "col-md-4" : "col-md-3";

    return (
        <div className="table">
            <div className="row header m-0 shadow-lg" style={{ background: '#1e1e1e' }}>
                {headers.map(item => <div className={handleReturnClassName() + " text-center"}>
                    <h5 className="mb-0" style={{ color: '#fff' }}>{item}</h5>
                </div>)}
            </div>
            <div className="table-card p-0" style={{ marginTop: -10 }}>
                {dataset?.map(item => (
                    <div
                        onClick={() => onOpen(item)}
                        className="row box m-0 mt-2"
                        style={{ border: 'none', borderBottom: '1px solid #efefef', borderRadius: 0 }}
                    >
                        <h6 onClick={() => onView(item)} className="mb-0" style={{ color: item?.tb1?.color ? item?.tb1?.color : THEME.greyish, flex: 1 }}>
                            {item.tb1.image && <img src={item.tb1.image} className="image" />}
                            {item?.tb1?.name}
                        </h6>
                        <h6 className="mb-0" style={{
                            color: item?.tb2?.color ? item?.tb2?.color : THEME.greyish, flex: 1, overflow: 'hidden',
                            textOverflow: 'ellipsis', maxHeight: 100
                        }}>
                            {item?.tb2?.name}
                        </h6>
                        <h6 className="mb-0" style={{ color: item?.tb3?.color ? item?.tb3?.color : THEME.greyish, flex: 1 }}>{item?.tb3?.name}</h6>
                        {exceptionalClick && <select
                            value={item?.tb4?.status}
                            style={{ width: 220, textAlign: 'center' }}
                            className="bg-slate-100 p-3 rounded-full mr-10"
                            onChange={e => onStatus(item, e.target.value)}>
                            {userStatus.map(item => <option value={item.value}>{item.name}</option>)}
                        </select>}
                        {!exceptionalClick && item.tb4 && <h6 className="mb-0" onClick={() => !exceptionalClick ? console.log(item) : onStatus(item)}
                            style={{
                                color: exceptionalClick ? '#fff' : item?.tb4?.color ? item?.tb4?.color : THEME.greyish, flex: 1,
                                borderRadius: 100,
                                backgroundColor: !exceptionalClick ? 'rgba(0,0,0,0)' : item?.tb4?.name == 'Approved' ? '#2D398B' : '#01ACEE',
                            }}>{item?.tb4?.name}</h6>}



                    </div>
                ))}
            </div>
        </div>
    );
}

export default TabularItem;