import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header, HOC, HomeItem } from "../components";
import { HOMEOPTIONS } from "../utils/_dataset";
import { Icons } from "../assets";
import { Button } from "@mui/material";
import { _call } from "../utils/_api";

const MembershipScreen = (props) => {
    const [price, setprice] = useState('');
    const [priceText, setpriceText] = useState('');
    const [toggle, settoggle] = useState(false);
    const [data, setdata] = useState([]);

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {
        try {
            const _getPricing = await _call('api/v1/payment/get_subscription_price/', 'GET');
            setdata(_getPricing?.data?.data[0]);
            setprice(_getPricing.data?.data[0]?.unit_amount / 100)
            setpriceText(_getPricing?.data?.data[0]?.metadata?.additional_text)
        } catch (err) {
            console.log(err);
        }
    }

    const handleOperation = async () => {
        if (!toggle) {
            settoggle(true);
            return;
        }

        if (price == '') {
            return;
        }


        try {

            const _editPricing = await _call('api/v1/payment/create_subscription_price/', 'POST', {
                amount: parseInt(price),
                price_id: data?.id,
                product_id: data?.product,
                "metadata": {
                    "additional_text": priceText
                }
            });

            settoggle(false);

        } catch (err) {
            console.log(err);
            settoggle(false);
        }


    }

    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pr-4">
                    <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                        <img src={Icons.SPECIALISTICON} style={{ width: 25, margin: 'auto', }} /></div>
                    <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Membership</h3>
                    <p className="text-center">This is the admin panel membership</p>
                    <div className="pt-3" />
                    <div className="row" style={{ borderTop: '1px solid #D3D2D2' }}>
                        <div className="shadow-lg h-96 bg-white card w-100 mt-10 pt-3">
                            <h3 className="text-center mt-3 mb-10" style={{ fontSize: 16, fontFamily: 'Manrope-Bold' }}>Current Membership Amount</h3>
                            {!toggle ? <>
                                <h3 className="text-center mt-3" style={{ fontSize: 40, fontFamily: 'Manrope-Bold' }}>${price}</h3>
                                <p className="text-center mt-2">{priceText}</p>
                            </> :
                                <>
                                    <input className='q-input w-96 mt-2' value={price} onChange={e => setprice(e.target.value)}
                                        style={{ margin: 'auto', marginBottom: 0, fontSize: 20, fontWeight: "bold" }}
                                        placeholder='Membership Price' />
                                    <input className='q-input w-96' value={priceText} onChange={e => setpriceText(e.target.value)}
                                        style={{ margin: 'auto', marginBottom: 0, fontSize: 20, fontWeight: "bold" }}
                                        placeholder='Membership Price Text' />
                                </>
                            }
                            <Button
                                onClick={handleOperation}
                                className="checkedbox w-96 text-white"
                                style={{ height: 60, fontSize: 14, margin: 'auto', marginTop: 40 }}
                            >Update</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
}

const Membership = HOC(MembershipScreen, 0);

export default Membership;