import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import TabularItem from "../components/_tabularitem";
import { _call } from "../utils/_api";
import { THEME } from "../utils/_constant";
import _ from 'lodash';
import { QUESTIONDATA, SYMPTOMSDATA } from "../utils/_dataset";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";


const Questions = (props) => {
    const [dialogstate, setdialogstate] = useState({
        state: false,
        edit: false
    });
    const [loader, setloader] = useState(false);
    const navigate = useNavigate();
    const [selected, setselected] = useState(1);
    const [url, seturl] = useState('api/v1/question/?limit=15&offset=0');
    const [next, setnext] = useState(true);
    const [header, setheader] = useState([]);
    const [qnatype, setqnatype] = useState(1);
    const [painInfo, setpainInfo] = useState([]);
    const [selectedqna, setselectedqna] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    })


    useEffect(() => {
        handleData();
        handleGetQuestions();
    }, [next, url]);

    const handleData = async () => {

        try {
            const _data = await _call('api/v1/pain-category/', 'GET');
            setpainInfo(_data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGetQuestions = async () => {

        try {
            setloader(true);
            const _data = await _call('api/v1/general-question/?pain_category=' + selected, 'GET');
            const data = _data.data;
            var shape = [];
            data.map(item => {
                const obj = {
                    id: item.id,
                    selectedcategories: item.pain_category,
                    tb1: {
                        name: item.question,
                    },
                    tb2: {
                        name: item.sorting_number ? item.sorting_number : 0
                    },
                    tb3: {
                        name: item.mandatory ? 'Allowed' : 'Not Allowed',
                        color: item.mandatory ? THEME.green : THEME.red
                    },
                }
                shape.push(obj);
            })
            if (!_.isEmpty(shape)) {
                setdataset(shape);
            }
            setloader(false);
            setheader([]);
            // setpainInfo(_data.data);
        } catch (err) {
            setloader(false);
            console.log(err);
        }
    }

    const handleGetOtherQuestions = async () => {

        try {
            setloader(true);
            const _data = await _call('api/v1/question/?pain_category=' + selected, 'GET');
            const data = _data.data;
            seturl(_data.data.next);
            var shape = [];
            data.map(item => {
                const obj = {
                    id: item.id,
                    selectedcategories: item.pain_category,
                    conditions: item.condition,
                    tb1: {
                        name: item.question,
                    },
                    tb2: {
                        name: item.sorting_number ? item.sorting_number : 0
                    },
                    tb3: {
                        name: item.answers.length
                    },
                    tb4: {
                        name: item.allow_multi_select ? 'Allowed' : 'Not Allowed',
                        color: item.allow_multi_select ? THEME.green : THEME.red
                    },
                }
                shape.push(obj);
            });

            setnext(false);
            if (!_.isEmpty(shape)) {
                setdataset(shape);
            }
            setloader(false);
            setheader(["Question", "Sorting Number", "Answers Count", "Multi Select"])
        } catch (err) {
            console.log(err);
            setloader(false);
        }
    }

    const handleSave = (type, data) => {
        var datasetclone = [...dataset];
        setloader(true);
        setselectedqna([]);
        if (type == 1) {
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully saved',
                mode: 1
            });
            setqnatype(1)
            datasetclone.unshift({
                id: data.id,
                selectedcategories: data.pain_category,
                tb1: {
                    name: data.question,
                },
                tb2: {
                    name: data.sorting_number ? data.sorting_number : 0
                },
                tb3: {
                    name: data.mandatory ? 'Allowed' : 'Not Allowed',
                    color: data.mandatory ? THEME.green : THEME.red
                },
            })

        } else if (type == 2) {
            setdialogstate({ ...dialogstate, state: false });
            setqnatype(2)
            setresponseobj({
                state: true,
                message: 'Successfully updated',
                mode: 1
            });
            datasetclone.unshift({
                id: data.id,
                selectedcategories: data.pain_category,
                conditions: data.conditions,
                tb1: {
                    name: data.question,
                },
                tb2: {
                    name: data.sorting_number ? data.sorting_number : 0
                },
                tb3: {
                    name: data.answers
                },
                tb4: {
                    name: data.multiselect ? 'Allowed' : 'Not Allowed',
                    color: data.multiselect ? THEME.green : THEME.red
                },
            })
        } else if (type == 3) {
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully updated',
                mode: 1
            });
            const dIndex = datasetclone.findIndex(x => x.id == data.id);
            datasetclone[dIndex] = {
                id: data.id,
                selectedcategories: data.pain_category,
                tb1: {
                    name: data.question,
                },
                tb2: {
                    name: data.sorting_number ? data.sorting_number : 0
                },
                tb3: {
                    name: data.mandatory ? 'Allowed' : 'Not Allowed',
                    color: data.mandatory ? THEME.green : THEME.red
                },
            }
        } else if (type == 4) {
            const dIndex = datasetclone.findIndex(x => x.id == data.id);

            datasetclone[dIndex] = {
                id: data.id,
                selectedcategories: data.pain_category,
                conditions: data.conditions,
                tb1: {
                    name: data.question,
                },
                tb2: {
                    name: data.sorting_number ? data.sorting_number : 0
                },
                tb3: {
                    name: data.answers
                },
                tb4: {
                    name: data.multiselect ? 'Allowed' : 'Not Allowed',
                    color: data.multiselect ? THEME.green : THEME.red
                },
            }
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully updated',
                mode: 1
            });

        } else if (type == 5) {
            datasetclone = datasetclone.filter(x => x.id != data.id)
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully updated',
                mode: 1
            });
        } else {
            setresponseobj({
                state: true,
                message: 'Some problem occured',
                mode: 0
            })
        }
        setloader(false);
        setdataset(datasetclone);
    }

    useEffect(() => {
        if (parseInt(qnatype) == 1) {
            setdataset([]);
            handleGetQuestions();
        } else {
            setdataset([]);
            handleGetOtherQuestions();
        }
    }, [qnatype]);

    useEffect(() => {
        if (parseInt(qnatype) == 1) {
            setdataset([]);
            handleGetQuestions();
        } else {
            setdataset([]);
            handleGetOtherQuestions();
        }
    }, [selected]);

    return (
        <div className="mytmg-container">
            <div className="p-5">
                {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}
                <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                    <img src={Icons.QUESTIONICON} style={{ width: 25, margin: 'auto', }} /></div>
                <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Questions</h3>
                <p className="text-center">This is the admin panel diagnosis questions</p>
                <div className="pt-3" />
                <Header />
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                <div className="pr-4">
                    <div className="row m-0 text-center">
                        <div className="col-md-12 row">
                            <select onChange={e => setqnatype(e.target.value)} className="mr-2 selectinput">
                                <option value="1">General Questions</option>
                                <option value="2">Other Questions</option>
                            </select>
                            <select className="mr-2 selectinput" onChange={e => setselected(e.target.value)}>
                                {painInfo.map(item => <option value={item.id}>{item.category_name}</option>)}
                            </select>
                            <Button
                                onClick={() => navigate('/question-detail')}
                                className="header-btn"
                                style={{ background: '#1e1e1e', border: 'none', width: 220 }}
                            >
                                <img src={Icons.WHITEPLUSICON} />
                                <h4 style={{ fontSize: `12px !important` }}>New Questionaire</h4>
                            </Button>
                            <Button
                                onClick={() => navigate('/create-picture-flow')}
                                className="header-btn ml-2"
                                style={{ background: '#1e1e1e', border: 'none', width: 220 }}
                            >
                                <img src={Icons.WHITEPLUSICON} />
                                <h4 style={{ fontSize: `12px !important` }}>Add Picture Based</h4>
                            </Button>
                        </div>

                    </div>
                    <div className="pt-3" />

                    <div className="w-100">
                        <TabularItem onOpen={item => {
                            setselectedqna(item);
                            if (qnatype == 1) {
                                setdialogstate({ edit: true, state: true });
                            } else {
                                navigate('/edit-question', {
                                    state: {
                                        data: item,
                                        paindata: painInfo
                                    }
                                })
                            }



                        }} header={header} data={QUESTIONDATA} dataset={dataset} />
                        <Button className="float-right"
                            onClick={() => setnext(true)}
                        >Next</Button>
                    </div>
                </div>
            </div>
            <Dialog.QuestionCE dataset={selectedqna} selection={_.isEmpty(selectedqna) ? -1 : qnatype} state={dialogstate.state} onSave={(type, data) => handleSave(type, data)} painInfo={painInfo} onClose={() => setdialogstate({ ...dialogstate, state: false })} />
        </div >
    );
}

const QuestionsScreen = HOC(Questions, 1);

export default QuestionsScreen;