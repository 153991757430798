import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Header, HOC, HomeItem, Dialog } from "../components";
import TabularItem from "../components/_tabularitem";
import { _call } from "../utils/_api";
import { THEME } from "../utils/_constant";
import { CLIENTDATA, ONBOARDINGDATA, SYMPTOMSDATA } from "../utils/_dataset";

const searchTypes = ["Name", "Email", "License"];

const Onboarding = (props) => {
    const [type, settype] = useState(0);
    const [painInfo, setpainInfo] = useState([]);
    const [loader, setloader] = useState(false);
    const [dataset, setdataset] = useState([]);
    const [alldataset, setalldataset] = useState([]);
    const [selected, setselected] = useState('');
    const [searchType, setsearchType] = useState('Name');
    const [search, setsearch] = useState('');
    const [dataInfo, setdataInfo] = useState({
        state: false,
        data: [],
    })


    useEffect(() => {
        handleData();
        handleGetSpecialist();
    }, []);

    const handleData = async () => {

        try {

            const _data = await _call('api/v1/specialist-area/', 'GET');
            console.log(_data.data, 'asddas')
            setpainInfo(_data.data);
            setloader(false);
        } catch (err) {
            console.log(err);
            setloader(false);
        }
    }

    const handleGetSpecialist = async (pain = 6) => {

        try {
            setloader(true);
            const _data = await _call('api/v1/specialist/?specialist_type=' + pain, 'GET');
            const data = _data.data;
            var shape = [];
            data.map(item => {
                const obj = {
                    id: item.id,
                    name: (item?.first_name + ' ' + item.last_name).trim(),
                    email: item?.email,
                    license_number: item?.license_number,
                    data: item,
                    tb1: {
                        name: `${(item.first_name + ' ' + item.last_name).trim()}\n (${item?.email})`,
                    },
                    tb2: {
                        name: item.specialistprofile?.speciality_area_details[0]?.speciality_name
                    },
                    tb3: {
                        name: item.specialistprofile?.license_number
                    },
                    tb4: {
                        name: handleReturnStatus(item.specialistprofile.status),
                        color: THEME.greyish,
                        status: item.specialistprofile.status
                    }
                }
                shape.push(obj);
            })
            setloader(false);
            console.log(shape)
            handleReturnData(0, shape);
            setdataset(shape);
            setalldataset(shape);
        } catch (err) {
            setloader(false);
            console.log(err);
        }
    }

    const handleReturnStatus = (status) => {

        switch (status) {
            case 0:
                return 'Pending';
            case 1:
                return 'Review In Progress';
            case 2:
                return 'Approved';
            case 3:
                return 'Suspended';
            case 4:
                return 'Blocked'
            case 5:
                return 'Temporary Suspended'
        }

        return 'Pending';
    }

    useEffect(() => {
        handleReturnData(type);
    }, [type]);

    const handleReturnData = (type, data = alldataset) => {
        // if (type == 0) {
        //     setdataset(data.filter(x => x.tb4 == 'Approved'));
        // } else {
        //     setdataset(data.filter(x => x.tb4 != 'Approved'));
        // }
        setdataset(data);
    }

    const handleChangeStatus = async (item, status) => {

        try {
            const _changeStatus = await _call(`api/v1/specialist/${item.id}/update-status/`, 'POST', {
                status
            });

            var _dataset = [...alldataset];
            const index = _dataset.findIndex(x => x.id == item.id);
            _dataset[index].tb4.name = handleReturnStatus(status)
            _dataset[index].tb4.status = status
            setdataset(_dataset);
            setalldataset(_dataset)

        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        handleGetSpecialist(selected);
    }, [selected]);


    useEffect(() => {
        handleSearch();
    }, [search]);

    const handleSearch = () => {
        var _dataset = [...alldataset];
        if (searchType?.toLowerCase() == 'name') {
            _dataset = _dataset.filter(x => x?.name?.toLowerCase().includes(search.toLowerCase()))
        } else if (searchType.toLowerCase() == 'email') {
            _dataset = _dataset.filter(x => x?.email?.toLowerCase().includes(search.toLowerCase()))
        } else if (searchType.toLowerCase() == 'license') {
            _dataset = _dataset.filter(x => x?.license?.toLowerCase().includes(search.toLowerCase()))
        }
        setdataset(_dataset);
    }

    const handleOnViewSpecialist = (item) => {
        setdataInfo({
            state: true,
            data: item
        })
    }

    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pr-4 client-section">
                    {loader &&
                        <div className='text-center'>
                            <CircularProgress color="inherit" />
                            <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                            <h6 className='flexfull mt-2'>Please wait...</h6>
                        </div>
                    }
                    <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                        <img src={Icons.SPECIALISTICON} style={{ width: 25, margin: 'auto', }} /></div>
                    <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Specialist</h3>
                    <p className="text-center">This is the admin panel specialist</p>
                    <input className="text-center mt-3" style={{ margin: 'auto', display: "block", width: 400, height: 60 }} placeholder="Search Name" value={search} onChange={e => setsearch(e.target.value)} />
                    <div className="text-center">
                        <select className="mr-2 selectinput mt-3" onChange={e => setselected(e.target.value)}>
                            <option>Select any option</option>
                            {painInfo.map(item => <option value={item.id}>{item.speciality_name}</option>)}
                        </select>
                        <select className="mr-2 selectinput mt-3" onChange={e => setsearchType(e.target.value)}>
                            {searchTypes.map(item => <option value={item}>{item}</option>)}
                        </select>
                    </div>
                    <div className="row m-0 mt-4 onboarding-line">
                        <Button onClick={() => settype(0)} className={type == 0 ? "tab activated" : "tab"}>
                            <h3 className={type == 0 ? "primary-colored activated flexfull" : "primary-colored flexfull"} style={{ fontSize: 16 }}>Onboared Specialist</h3>
                        </Button>
                        <Button onClick={() => settype(1)} className={type == 1 ? "tab activated" : "tab"}>
                            <h3 className={type == 1 ? "primary-colored activated flexfull" : "primary-colored flexfull"} style={{ fontSize: 16 }}>Pending Specialist</h3>
                        </Button>
                    </div>

                    {/* <input className="mr-2 selectinput mt-3 w-100" placeholder="Search" value={search} onChange={e => setsearch(e.target.value)} /> */}
                    <div className="pt-3" />

                    <div className="w-100">
                        <TabularItem
                            onView={handleOnViewSpecialist}
                            exceptionalClick={type == 1} onStatus={(item, status) => handleChangeStatus(item, status)} data={ONBOARDINGDATA}
                            dataset={dataset} />
                    </div>
                </div>

            </div>

            <Dialog.Specialist state={dataInfo.state} onClose={() => setdataInfo({ ...dataInfo, state: false })} data={dataInfo.data} />
        </div>
    );
}

const OnboardingScreen = HOC(Onboarding, 3);

export default OnboardingScreen;