const URL = "https://api.mytmjapp.com/";

const THEME = {
    green: '#02A025',
    red: '#EE4444',
    greyish: '#878FAD'
}

export {
    THEME,
    URL
}