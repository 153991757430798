import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, imageListItemBarClasses, Input } from '@mui/material';
import _, { isEmpty } from 'lodash'
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { _call } from '../../utils/_api';

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement<any, any>;
//     },
//     ref: React.Ref<unknown>,
// ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const QuestionModal = (props) => {
    const { state, onClose, painInfo, onSave, dataset, selection = -1 } = props;
    const [tab, settab] = useState(1);
    const [question, setquestion] = useState('');
    const [loader, setloader] = useState(false);
    const [answer, setanswer] = useState('');
    const [qnsInfo, setqnsInfo] = useState({
        mandatory: true,
        sorting_number: '',
        multi: false
    });
    const [selected, setselected] = useState([...painInfo]);
    const [tab2selected, settab2selected] = useState([]);
    const [answers, setanswers] = useState([]);
    const [conditions, setconditions] = useState([]);
    const [selectedconditions, setselectedconditions] = useState([]);


    useEffect(() => {
        handleData();

    }, [dataset]);

    const handleData = async () => {
        if (!_.isEmpty(dataset)) {
            if (selection == "1") {
                setquestion(dataset?.tb1?.name);
                setqnsInfo({ ...qnsInfo, sorting_number: dataset?.tb2?.name, mandatory: dataset?.tb3?.name });
                var selectedcategories = [];
                dataset?.selectedcategories.map(item => {
                    selectedcategories.push(painInfo.find(x => x.id == item));
                });
                setselected(selectedcategories);
                settab(1);
            } else {
                setquestion(dataset?.tb1?.name);
                setqnsInfo({ ...qnsInfo, sorting_number: dataset?.tb2?.name, multi: dataset?.tb4?.name == 'False' ? 0 : 1 });
                const _getcondition = await _call(`api/v1/condition/?pain_category=${dataset?.selectedcategories}`, 'GET');
                setconditions(_getcondition.data);
                var selectedcategories = painInfo.find(x => x.id == dataset?.selectedcategories);
                settab2selected(selectedcategories);
                settab(2);
                handleGetAnswers()
            }
        } else {

        }
    }

    const handleGetAnswers = async () => {

        try {

            const _getdata = await _call(`api/v1/answer/?question=${dataset.id}`, 'GET');
            const _data = _getdata.data;
            var answersclone = [];
            var _set = new Set();
            _data.map(item => {
                answersclone.push({
                    text: item.answer,
                    index: item.id,
                    state: true,
                    item: item
                })
                _set.add(...item.condition)
            })
            setselectedconditions([..._set])
            setanswers(answersclone)

        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        setselected(painInfo)
    }, [painInfo]);

    const handleRemoveAnswer = (item) => {
        var answerscloneItem = [...answers];
        var index = answerscloneItem.findIndex(x => x == item);
        answerscloneItem[index].state = !answerscloneItem[index].state;
        setanswers(answerscloneItem)
    }

    const handleSaveAnswer = () => {
        var answersclone = [...answers];
        answersclone.push({
            text: answer,
            index: answersclone.length,
            state: true,
            conditions: selectedconditions
        })
        setanswers(answersclone);
        setselectedconditions(conditions.map(item => item.id))
        setanswer('');
    }

    useEffect(() => {
        console.log(answers)
    }, [answers]);

    const handleReturnElement = (data, state, onClick) => (
        <button className='transparent-btn' onClick={onClick}>
            <Form.Check
                type={'radio'}
                id={data.index}
                label={data.text}
                checked={state}
            />
        </button>
    );

    const handleRemove = (item) => {
        var selectedclone = [...selected];
        var ifExist = selectedclone.filter(x => x.id == item.id);
        if (_.isEmpty(ifExist)) {
            selectedclone.push(item)
        } else {
            selectedclone = selectedclone.filter(x => x.id != item.id)
        }
        setselected(selectedclone)
    }

    const handleRemoveTab2 = async (item) => {
        settab2selected(item);
        try {
            const _getcondition = await _call(`api/v1/condition/?pain_category=${item.id}`, 'GET');
            setconditions(_getcondition.data);
            setselectedconditions(_getcondition.data.map(item => item.id));
        } catch (err) {
            console.log(err);
        }
    }

    const handleSave = async (type) => {
        setloader(true);
        try {

            if (type == 1) {
                var qnaarr = selected.map(item => item.id);
                const _dataset = {
                    question,
                    mandatory: qnsInfo.mandatory,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: qnaarr
                }
                const _createquestion = await _call('api/v1/general-question/', 'POST', _dataset);
                onSave(1, _createquestion.data);

            } else {

                const _dataset = {
                    question,
                    mandatory: qnsInfo.mandatory,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: tab2selected.id,
                    allow_multi_select: qnsInfo.multi,
                }

                const _createquestion = await _call('api/v1/question/', 'POST', _dataset);
                const _qid = _createquestion.data.id;

                var _condition = [];
                // const _getconditions = await _call('api/v1/condition/?pain_category=' + tab2selected.id, 'GET');
                // if (!_.isEmpty(_getconditions.data)) {
                //     _condition = [..._getconditions.data];
                // }

                var _answersInfo = [];


                var _answersInfo = await Promise.all(answers.map(async item => {
                    if (item.state) {
                        const obj = {
                            answer: item.text,
                            question: _qid,
                            condition: item.conditions,
                        }
                        const _createanswer = await _call('api/v1/answer/', 'POST', obj);
                        _answersInfo.push(_createanswer.data);
                    }
                }))

                await Promise.all([_answersInfo]);
                var _shape = {
                    answers: answers.length,
                    multiselect: qnsInfo.multi,
                    id: _qid,
                    question: question,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: tab2selected.id,
                    conditions: selectedconditions.map(item => item),
                };

                onSave(2, _shape);
            }
            setloader(false);
        } catch (err) {
            console.log(err);
            onSave(0);
            setloader(false);
        }
    }

    const handleUpdate = async (type) => {
        try {
            setloader(true)
            if (type == 1) {
                var qnaarr = selected.map(item => item.id);
                const _dataset = {
                    question,
                    mandatory: qnsInfo.mandatory,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: qnaarr
                }
                const _updatequestion = await _call(`api/v1/general-question/${dataset?.id}/`, 'PATCH', _dataset);
                onSave(3, _updatequestion.data);

            } else {
                const _dataset = {
                    question,
                    mandatory: qnsInfo.mandatory,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: tab2selected.id,
                    allow_multi_select: qnsInfo.multi,
                }

                const _createquestion = await _call(`api/v1/question/${dataset.id}/`, 'PATCH', _dataset);
                const _qid = dataset.id;


                var _answersInfo = [];


                var _answersInfo = await Promise.all(answers.map(async item => {
                    if (item.state) {
                        var _request;
                        const obj = {
                            answer: item.text,
                            question: _qid,
                            condition: item.conditions,
                        }
                        if (item.item) {
                            _request = await _call(`api/v1/answer/${item.index}/`, 'PATCH', obj);
                        } else {
                            _request = await _call(`api/v1/answer/`, 'POST', obj);
                        }
                        _answersInfo.push(_request.data);
                    } else if (!item.state && item.item) {
                        // const remove = await _call(`api/v1/answer/${item.index}/`, 'DELETE');
                    }
                }))

                await Promise.all([_answersInfo]);

                var _shape = {
                    answers: answers.length,
                    multiselect: qnsInfo.multi,
                    id: _qid,
                    question: question,
                    sorting_number: parseInt(qnsInfo.sorting_number),
                    pain_category: tab2selected.id,
                    conditions: [...selectedconditions].map(item => item),
                };

                onSave(4, _shape);
            }
            setloader(false);
        } catch (err) {
            console.log(err);
            onSave(0);
            setloader(false);
        }

    }

    const renderConditions = (item) => {
        var conditionsArr = [];
        item.conditions?.map(item => {
            conditionsArr.push(
                conditions.find(x => x.id == item)?.name
            )
        });

        if (conditionsArr) {
            return conditionsArr.toString();
        }
        return '';
    }

    const handleDelete = async (type) => {
        setloader(true);
        try {

            if (type == 1) {

                const _deleteqna = await _call(`api/v1/general-question/${dataset.id}/`, 'DELETE');
                console.log(_deleteqna);
                onSave(5, dataset);

            } else {
                const _deleteqna = await _call(`api/v1/question/${dataset.id}/`, 'DELETE');
                console.log(_deleteqna);
                onSave(5, dataset);
            }
            setloader(false);

        } catch (err) {
            console.log(err);
            setloader(false);
        }

    }

    const handleUpdateConditions = async (item) => {

        var selectedconditionsclone = [...selectedconditions];
        if (selectedconditionsclone.includes(parseInt(item.id))) {
            selectedconditionsclone = selectedconditionsclone.filter(x => x != item.id);
        } else {
            selectedconditionsclone.push(item.id)
        }
        setselectedconditions(selectedconditionsclone);
    }

    return (
        <Dialog
            open={state}
            // TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='mytmj-modal'>
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>Loading...</h5>
                    </div>
                }
                <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull'>{_.isEmpty(dataset) ? 'Add' : 'Edit'} Questionaire</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} className="cross-icon" />
                    </div>

                    {_.isEmpty(dataset) && <>
                        <div className='row mt-3 ml-0'>
                            <Button style={{ backgroundColor: tab == 1 ? THEME.green : '#fff', color: tab == 1 ? '#fff' : THEME.green }} variant="outline" onClick={() => settab(1)}>General</Button>
                            <Button className='ml-2' style={{ backgroundColor: tab == 2 ? THEME.green : '#fff', color: tab == 2 ? '#fff' : THEME.green }} variant="outline" onClick={() => settab(2)}>Multi Question</Button>
                        </div>
                    </>}

                    {tab == 1 ?
                        <>
                            <div className='body mt-4'>
                                <h6>Question</h6>
                                <input className='input' value={question} onChange={e => setquestion(e.target.value)} placeholder='Type your question here' />

                                <div className='pt-3' />
                                <h6>Pain Categories</h6>
                                <div className='row mt-1 ml-0'>
                                    {painInfo.map(item => <Button style={{ borderWidth: isEmpty(selected.filter(x => x.id == item.id)) ? 1 : 0, borderColor: THEME.green, fontSize: 10, margin: 3, backgroundColor: !_.isEmpty(selected.filter(x => x.id == item.id)) ? THEME.green : '#fff', color: !_.isEmpty(selected.filter(x => x.id == item.id)) ? '#fff' : THEME.green }}
                                        variant="outline" onClick={() => handleRemove(item)}>{item.category_name}</Button>)}
                                </div>
                                <div className='pt-2' />
                                <h6>Sort By</h6>
                                <input type="number" className='input' value={qnsInfo.sorting_number} onChange={e => setqnsInfo({ ...qnsInfo, sorting_number: e.target.value })} placeholder='Sort it by' />
                                <div className='pt-2' />
                                {handleReturnElement({ index: 1, text: 'Mandatory' }, qnsInfo.mandatory, () => setqnsInfo({ ...qnsInfo, mandatory: !qnsInfo.mandatory }))}

                                <div className='pt-2' />
                                <Button onClick={() => {
                                    if (_.isEmpty(dataset)) {
                                        handleSave(1)
                                    } else {
                                        handleUpdate(1)
                                    }
                                }} className="primary-btn mt-3" >
                                    {_.isEmpty(dataset) ? 'Save Questionaire' : 'Update Questionaire'}
                                </Button>
                                {!_.isEmpty(dataset) &&
                                    <Button className="transparent-btn"
                                        onClick={() => handleDelete(1)}
                                        style={{ color: 'red', margin: 'auto', display: 'block' }}>
                                        Delete Question
                                    </Button>
                                }
                            </div>
                        </>
                        :
                        <>
                            <div className='body mt-4'>
                                <h6>Question</h6>
                                <input className='input' value={question} onChange={e => setquestion(e.target.value)} placeholder='Type your question here' />
                                <div className='pt-3' />
                                <h6>Pain Categories</h6>
                                <div className='row mt-1 ml-0'>
                                    {painInfo.map(item => <Button style={{ borderWidth: !(tab2selected == item) ? 1 : 0, borderColor: THEME.green, fontSize: 10, margin: 3, backgroundColor: (tab2selected == item) ? THEME.green : '#fff', color: (tab2selected == item) ? '#fff' : THEME.green }}
                                        variant="outline" onClick={() => handleRemoveTab2(item)}>{item.category_name}</Button>)}
                                </div>


                                <div className='pt-3' />
                                {handleReturnElement({ index: 1, text: 'Mandatory' }, qnsInfo.mandatory, () => setqnsInfo({ ...qnsInfo, mandatory: !qnsInfo.mandatory }))}
                                {handleReturnElement({ index: 2, text: 'Multi Select' }, qnsInfo.multi, () => setqnsInfo({ ...qnsInfo, multi: !qnsInfo.multi }))}
                                <div className='pt-3' />
                                <h6>Sort By</h6>
                                <input type="number" className='input' value={qnsInfo.sorting_number} onChange={e => setqnsInfo({ ...qnsInfo, sorting_number: e.target.value })} placeholder='Sort it by' />
                                <div className='pt-3' />
                                <h6>Answers</h6>
                                <div className='row m-0' style={{ justifyContent: 'space-between' }}>
                                    <input className='input' style={{ width: '100%' }} value={answer} onChange={e => setanswer(e.target.value)} placeholder='Type your question here' />

                                </div>
                                {!_.isEmpty(conditions) && <>
                                    <div className='row mt-2 ml-0'>
                                        {conditions.map(item => <Button style={{
                                            borderWidth: !selectedconditions.includes(item.id) ? 1 : 0, borderColor: THEME.green, fontSize: 10, margin: 3,
                                            backgroundColor: selectedconditions.includes(item.id) ? THEME.green : '#fff', color: selectedconditions.includes(item.id) ? '#fff' : THEME.green
                                        }}
                                            variant="outline" onClick={() => handleUpdateConditions(item)}>{item.name}</Button>)}
                                    </div>
                                </>}

                                <Button onClick={() => handleSaveAnswer()} className='btn btn-block mt-2'>Save</Button>
                                <div className='pt-3' />
                                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                    {answers.map((item, i) => {
                                        return (
                                            handleReturnElement({ index: i, text: `${item.text} (${renderConditions(item)})` }, item.state, () => handleRemoveAnswer(item))
                                        );
                                    }
                                    )}
                                </div>

                                <Button onClick={() => {
                                    if (_.isEmpty(dataset)) {
                                        handleSave(2)
                                    } else {
                                        handleUpdate(2)
                                    }

                                }} className="primary-btn mt-3" >
                                    {_.isEmpty(dataset) ? 'Save Questionaire' : 'Update Questionaire'}
                                </Button>
                                {!_.isEmpty(dataset) &&
                                    <Button className="transparent-btn"
                                        onClick={() => handleDelete(2)}
                                        style={{ color: 'red', margin: 'auto', display: 'block' }}>
                                        Delete Question
                                    </Button>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default QuestionModal;