import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { _call } from '../../utils/_api';

const SpecialistDialog = (props) => {
    const { state, onClose, onSave, data } = props;
    
    console.log(data,'2131')

    return (
        <Dialog
            open={state}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='mytmj-modal'>
            <div className='header row m-0'>
                <h3 className='flexfull'>Specialist Information</h3>
                <img onClick={onClose} src={Icons.CROSSICON} className="cross-icon" />
            </div>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">Full Name: <span style={{color:'#afafaf'}}>{data?.name}</span></h6>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">Email: <span style={{color:'#afafaf'}}>{data?.email}</span></h6>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">License Number: <span style={{color:'#afafaf'}}>{data?.data?.specialistprofile?.license_number}</span></h6>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">Phone Number: <span style={{color:'#afafaf'}}>{data?.data?.specialistprofile?.phone_number}</span></h6>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">Specialist Area: <span style={{color:'#afafaf'}}>{data?.data?.specialistprofile?.speciality_area_details?.map(item => item?.speciality_name).toString()}</span></h6>
            <br/>
            <h6 style={{ fontSize: 14 }} className="mb-1">NPI: <span style={{color:'#afafaf'}}>{data?.data?.specialistprofile?.npi}</span></h6>
            </div>
        </Dialog>
    );
}

export default SpecialistDialog;