import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { Editor } from '@tinymce/tinymce-react';
import { _call } from '../../utils/_api';

const ContentEdit = (props) => {
    const { state, onClose, onSave, data } = props;
    const [loader, setloader] = useState(false);
    const [categoryInfo, setcategoryInfo] = useState('');
    const [description, setdescription] = useState('');
    const [url, seturl] = useState('');

    const editorRef = useRef(null);

    useEffect(() => {
        setcategoryInfo(data.category_name);
        setdescription(data.content);
        seturl(data.url)
    }, [data]);

    const handleSave = async () => {

        try {
            setloader(true)
            const _dataset = {
                category_name: categoryInfo,
                content: editorRef?.current?.getContent(),
                url: url
            };

            const _createCategory = await _call(`api/v1/sub-category/${data.id}/`, 'PATCH', _dataset);

            setcategoryInfo('');
            setdescription('');
            seturl('');
            onSave(_createCategory.data);
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }

    }

    return (
        <Dialog
            open={state}

            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='p-4'>
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>Loading...</h5>
                    </div>
                }
                <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull primary-colored'>Edit Content</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} style={{ width: 20, height: 20 }} />
                    </div>
                    <p style={{ color: '#afafaf' }}>
                        You can create category which will be used to create content
                    </p>
                    <div className="pt-2" />
                    <h4 style={{ fontSize: 16 }}>Sub Category Name</h4>
                    <input className='q-input w-100 mt-2'
                        placeholder='Sub Category Name'
                        value={categoryInfo}
                        onChange={e => setcategoryInfo(e.target.value)}
                        style={{ borderLeft: '1px solid #fff', borderRight: '1px solid #fff' }} />
                    <h6 className='mt-4'>URL</h6>
                    <input value={url} onChange={e => seturl(e.target.value)} className="border p-3 mt-2 mb-2 rounded w-100" placeholder="Add Url" />
                    <div className="mt-4" />
                    <h4 style={{ fontSize: 16 }}>Content</h4>
                    <div className="mt-2" />
                    <Editor
                        onInit={(evt, editor) => {
                            editorRef.current = editor
                        }}
                        apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                        initialValue={description || data.content}
                        init={{
                            height: 400,
                            menubar: true,
                            image_title: true,
                            file_picker_types: 'file image media',
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste image code help wordcount",
                            ],
                            toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                            content_style:
                                `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                        }}
                    />
                    <div className="mt-4" />
                    <Button
                        onClick={handleSave}
                        className="checkedbox"
                        style={{ height: 60, width: '100%', fontSize: 14, }}
                    >Update</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ContentEdit;