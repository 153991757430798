import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Header, HOC, HomeItem, ChartView, CuTabularComp } from "../components";
import {
    HOMEOPTIONS, USERCOLUMNS, CONDITIONSCOLUMN,
    CATEGORYCOLUMN
} from "../utils/_dataset";
import { TableCell, TableRow } from "@mui/material";
import { _call } from '../utils/_api';
import { Icons } from "../assets";

const ClientAnalytics = (props) => {
    const [dataset, setdataset] = useState([]);


    useEffect(() => {
        handleData();
    }, []);


    const handleData = async () => {
        try {

            const _getUserData = await _call('api/v1/user-count/', 'GET');
            const _getActiveUser = await _call('api/v1/user-count/?filter_type=4', 'GET');
            const _getCommonCondition = await _call('api/v1/common-condition/', 'GET');
            const _getCommonPainCategory = await _call('api/v1/common-pain-category/', 'GET');

            setdataset({
                userData: _getUserData.data,
                activeUserData: _getActiveUser.data,
                commonConditionData: _getCommonCondition.data,
                commonPainCategory: _getCommonPainCategory.data
            })

        } catch (err) {
            console.log(err);
        }
    }


    const RenderChildren = () => {
        return (
            <>
                {dataset?.userData?.map((row) => (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.user_type}
                        </TableCell>
                        <TableCell align="left">{row.count}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const RenderChildren2 = () => {
        return (
            <>
                {dataset?.activeUserData?.map((row) => (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.user_type}
                        </TableCell>
                        <TableCell align="left">{row.count}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const RenderChildren3 = () => {
        return (
            <>
                {dataset?.commonConditionData?.map((row) => (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.condition}
                        </TableCell>
                        <TableCell align="left">{row.count}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const RenderChildren4 = () => {
        return (
            <>
                {dataset?.commonPainCategory?.map((row) => (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.pain_category}
                        </TableCell>
                        <TableCell align="left">{row.count}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pr-4 analytics-container">
                    <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                        <img src={Icons.ANALYTICSICON} style={{ width: 25, margin: 'auto', }} /></div>
                    <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Information Section</h3>
                    <p className="text-center">This is the admin panel information section</p>
                    <div className="pt-3" />
                    <div style={{ overflowX: 'scroll', marginTop: 20 }}>
                        <h5>User Data</h5>
                        <div className="pt-2" />
                        <CuTabularComp renderChildren={RenderChildren} data={dataset?.userData} columns={USERCOLUMNS} />
                    </div>

                    <div style={{ overflowX: 'scroll', marginTop: 40 }}>
                        <h5>Active User Data</h5>
                        <div className="pt-2" />
                        <CuTabularComp renderChildren={RenderChildren2} data={dataset?.activeUserData} columns={USERCOLUMNS} />
                    </div>

                    <div style={{ overflowX: 'scroll', marginTop: 40 }}>
                        <h5>Common Conditions</h5>
                        <div className="pt-2" />
                        <CuTabularComp renderChildren={RenderChildren3} data={dataset?.commonConditionData} columns={CONDITIONSCOLUMN} />
                    </div>

                    <div style={{ overflowX: 'scroll', marginTop: 40 }}>
                        <h5>Common Categories</h5>
                        <div className="pt-2" />
                        <CuTabularComp renderChildren={RenderChildren4} data={dataset?.commonPainCategory} columns={CATEGORYCOLUMN} />
                    </div>

                    <div className="row w-100">




                        {/* <div className="col-md-5">
                            <div className="card p-4">
                                <h6 >Most Selected Area</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="Mouth" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">Most Common Answer</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="I feel pain in my face, jaw, teeth" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">Most Common Condition</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="Serious" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">All Questionnaries Filled Out?</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="No" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">Most Common Symptom</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="Headache" className="input" />
                                </InputGroup>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    );
}

const ClientAnalyticsScreen = HOC(ClientAnalytics, 7);

export default ClientAnalyticsScreen;