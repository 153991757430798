import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { SCOREOPTIONS } from '../../utils/_dataset';
import { _call } from '../../utils/_api';


const __initialConditions = {
    condition: [],
    score: 1,
    id: _.uniqueId()
}

const QAnswer = (props) => {
    const { state, onClose, onResultClose, conditions, answers, setanswers, questionId } = props;
    const [loader, setloader] = useState(false);

    const [answer, setanswer] = useState('');
    const [selectedconditions, setselectedconditions] = useState([]);
    const [onSelectedConditions, setonSelectedConditions] = useState([__initialConditions]);



    const onSaveConditionsInfo = (value, key, item) => {
        var __selectedConditionsInfo = [...onSelectedConditions];
        var Index = __selectedConditionsInfo?.findIndex(x => x.id == item?.id)
        __selectedConditionsInfo[Index][key] = value;
        setonSelectedConditions(__selectedConditionsInfo)
    }


    const handleSaveAnswer = async () => {
        if (answer == '') return;

        var answersclone = [...answers];
        try {
            const obj = {
                answer: answer,
                question: questionId,
                condition_score: onSelectedConditions?.filter(x => x?.condition)?.map(item => {
                    return {
                        score: item.score,
                        condition: conditions?.find(x => x.id == item?.condition)?.id
                    }
                }),
            }

            const _createanswer = await _call('api/v1/answer/', 'POST', obj);
            setanswers([...answers, _createanswer?.data])
            onResultClose(1)
        } catch (err) {
            console.log(err)
            onResultClose(0)
        }

    }


    return (
        <Dialog
            open={state}

            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='p-4'>
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>Loading...</h5>
                    </div>
                }
                <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull primary-colored'>Add Answer</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} style={{ width: 20, height: 20 }} />
                    </div>
                    <p style={{ color: '#afafaf' }}>
                        You can create answer
                    </p>
                    <div className='w-full'>
                        <div className="mt-4" />
                        <h6 style={{ fontSize: 14 }} className="mb-1">Answer *</h6>
                        <input className='q-input w-full' style={{ width: '100%' }} value={answer} onChange={e => setanswer(e.target.value)} placeholder='Type your answer here' />
                        <div className="mt-4" />

                        {onSelectedConditions?.map((item, index) => {
                            return <div className="row m-0 mt-2" key={index}>
                                <div className="col-md-6 m-0 p-0">
                                    <h6 style={{ fontSize: 14 }} className="mb-1">Condition - {index + 1}</h6>
                                    <select onChange={e => onSaveConditionsInfo(e.target.value, 'condition', item)} className='q-input w-full focus:outline-none'>
                                        {conditions?.map((item, index) => {
                                            return <option value={item.id}>{item?.label}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-2 m-0 p-0 border-l-white">
                                    <h6 style={{ fontSize: 14 }} className="mb-1">Score</h6>
                                    <select onChange={e => onSaveConditionsInfo(e.target.value, 'score', item)} className='q-input w-full focus:outline-none border-l' placeholder="Score" >
                                        {SCOREOPTIONS.map(item => <option value={item}>{item}</option>)}
                                    </select>
                                    {/* <input className='q-input w-full focus:outline-none border-l' placeholder="Score" onChange={e => onSaveConditionsInfo(e.target.value, 'score', item)} /> */}
                                </div>
                                <div className="col-md-2 m-0 p-0">
                                    <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                    <Button
                                        onClick={() => setonSelectedConditions([...onSelectedConditions, { ...__initialConditions, id: _.uniqueId() }])}
                                        className="checkedbox"
                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                    >Save</Button>
                                </div>
                                <div className="col-md-2 m-0 p-0">
                                    <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                    <Button
                                        onClick={() => {
                                            setonSelectedConditions(onSelectedConditions?.filter(x => x.id != item?.id))
                                        }}
                                        style={{ height: 60, width: '100%', fontSize: 14, background: 'red' }}
                                    >Remove</Button>
                                </div>
                            </div>
                        })}

                    </div>


                    <div className="mt-4" />
                    <Button
                        onClick={() => handleSaveAnswer()}
                        className="checkedbox mt-4"
                        style={{ height: 60, width: '100%', fontSize: 14, }}
                    >Save Answer</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default QAnswer;