import React, { useState } from "react";
import SideBar from "./_sidebar";

function HOC(WrappedComponent, id) {
    return (props) => {
        return (
            <div className="row">
                <SideBar props={props} active={id} />
                <WrappedComponent {...props} />
            </div>
        );


    }
}

export default HOC;