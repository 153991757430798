import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../assets";
import { SIDEBAROPTIONS } from "../utils/_dataset";

const Sidebar = (props) => {
    const [options, setoptions] = useState(SIDEBAROPTIONS);
    const [active, setactive] = useState(0);
    const navigate = useNavigate();

    const handleNavigate = (item) => {

        if (item.id == 10) {
            localStorage.removeItem('token');
            navigate('login')
            return;
        }

        if (item.route) {
            navigate('/' + item.route)
        }

    }

    return (
        <div className="sidebar m-0">
            <img src={Icons.LOGO} className="logo" />
            <div className="pt-5" />
            {options.map((item, index) => {
                return (
                    <div onClick={() => handleNavigate(item)}
                        style={{ cursor: 'pointer' }} className={props.active == item.id ? "row w-100 Item active" : "row w-100 Item"}>
                        <div className="image-container">
                            <img src={item.image} />
                        </div>
                        <h5 className="ml-2 mt-1">{item.name}</h5>
                    </div>
                );
            })}
        </div>
    );
}

export default Sidebar;