import React, { useState } from "react";
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Icons } from "../assets";

const Header = (props) => {

    return (
        <div className="header">
            {/* <div className="row">
                <div className="col-md-6">
                    <InputGroup className="input-container">
                        <InputGroup.Text className="transparent-icon">
                            <img src={Icons.SEARCHICON} />
                        </InputGroup.Text>
                        <Form.Control placeholder="Search" className="input" />
                    </InputGroup>
                </div>
                <div className="offset-md-10 col-md-2 text-right">
                    <div className="text-right w-100" style={{ display: 'flex' }}>
                        <div className="icon-box">
                            <img src={Icons.SETTINGICON} />
                        </div>

                    </div>
                    <div className="user-box">
                        <div className="user-info">
                            <h5>Doherty Will</h5>
                            <h6>Admin</h6>
                        </div>
                        <img src={require('../assets/images/user1.png').default} />
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Header;