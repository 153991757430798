import { CircularProgress, Paper, TableCell, TableContainer, TableRow } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useState, useEffect, useRef } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { isEmpty } from 'lodash'
import { Icons } from "../assets";
import { Header, HOC, HomeItem, Response } from "../components";
import TabularItem from "../components/_tabularitem";
import { _call } from "../utils/_api";
import { CLIENTDATA, SYMPTOMSDATA } from "../utils/_dataset";
import { THEME } from "../utils/_constant";
import moment from 'moment';

const _initialEmail = {
    state: false,
    email: '',
    subject: '',
    message: ''
}

const Clients = (props) => {
    const [selected, setselected] = useState({
        state: false,
        data: []
    });
    const [alldata, setalldata] = useState([]);
    const [selectedInfo, setselectedInfo] = useState([])
    const [allshapedata, setallshapedata] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [emailObj, setemailObj] = useState(_initialEmail)
    const [search, setsearch] = useState('');
    const [loader, setloader] = useState(false);
    const [diagnosesInfo, setdiagnosesInfo] = useState([]);
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: '',
        mode: 0
    });

    var editorRef = useRef();

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            setloader(true)
            const _getdata = await _call('api/v1/client/', 'GET');
            console.log(_getdata.data)
            var _data = _getdata.data;
            var shape = [];

            _data.map((item, index) => {
                const obj = {
                    id: item.id,
                    tb1: {
                        name: index + 1
                    },
                    tb2: {
                        name: (item.first_name + ' ' + item.last_name).trim(),
                        image: item.profile_picture ? { uri: item.profile_picture } : null
                    },

                    tb3: {
                        name: item.clientprofile?.dob,
                    },
                    tb4: {
                        name: item.email
                    }
                }
                shape.push(obj);
            });

            setloader(false)
            setalldata(_data);
            setdataset(shape);
            setallshapedata(shape);

        } catch (err) {
            console.log(err);
            setloader(false)
        }

    }

    const handleOpenClient = async (item) => {

        var findItem = alldata.find(x => x.id == item.id);

        try {

            const _getDiagnose = await _call(`api/v1/diagnose/?created_by=${item.id}`, 'GET');

            var _shape = [];
            _getDiagnose?.data?.results.map(item => {
                const obj = {
                    id: item.id,
                    tb1: {
                        name: item?.condition_details?.map(i => i.name)?.toString() || 'Not Found'
                    },
                    tb2: {
                        name: item?.diagnose_pictures_qna_details ? item?.diagnose_pictures_qna_details.length : item?.diagnose_qna_details.length.toString() || 'Not Found',
                    },
                    tb3: {
                        name: (item?.first_name + ' ' + item?.last_name).trim() || 'Not Found'
                    },
                    ...item
                };
                return { ...item, obj }
            })
            setdiagnosesInfo(_getDiagnose.data.results);
        } catch (err) {
            console.log(err);
        }

        setselected({
            state: true,
            data: findItem
        });

        console.log(findItem)
    }

    useEffect(() => {

        if (search != '') {
            var dataclone = [...allshapedata];
            dataclone = dataclone.filter(x => x?.tb2?.name?.toLowerCase().includes(search.toLowerCase()));
            setdataset(dataclone);
        } else {
            setdataset(allshapedata);
        }

    }, [search]);


    const handleSendEmail = async () => {

        try {
            setloader(true);
            const _dataset = {
                to_email: emailObj?.email,
                body: emailObj?.message,
                subject: emailObj?.subject
            }
            const _composeEmail = await _call('api/v1/send-email/', 'POST', _dataset);
            setloader(false);
            setemailObj(_initialEmail);
            setresponseobj({
                mode: 1,
                message: 'Successfully sent',
                state: true
            })
        } catch (err) {
            setloader(false)
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }

    }

    const handleNavigate = (item) => {




    }


    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                {!isEmpty(selectedInfo) ? <>
                    <div className="w-100">
                        <div className="row m-0 align-item-center">
                            {selectedInfo.length != 0 && <img src={Icons.BACKICON} className="mr-3" onClick={() => !isEmpty(selectedInfo) && setselectedInfo([])} />}
                            <h3 className='flexfull' style={{ fontSize: 24 }}>{selectedInfo?.condition_details?.map(item => item.name)?.toString()}</h3>
                        </div>
                        {selectedInfo?.diagnose_qna_details.length > 0 &&
                            <div className="card p-4 pt-0 text-center mt-2" style={{ background: '#fff' }}>
                                <div className="p-3">
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell>Name: {selectedInfo?.first_name + ' ' + selectedInfo?.last_name}</TableCell>
                                                <TableCell align="center">Weight: {selectedInfo?.weight}</TableCell>
                                                <TableCell align="center">Height: {selectedInfo?.height}</TableCell>
                                                <TableCell align="center">Date of Birth: {selectedInfo?.dob}</TableCell>
                                                <TableCell align="center">Gender: {selectedInfo?.gender == 0 ? 'Male' : 'Female'}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="row m-0 m-1">
                                    {selectedInfo?.diagnose_qna_details?.map((item, index) => {
                                        return (
                                            <div className="col-6 mt-4" >
                                                <div className="card p-3 shadow-md" style={{ minHeight: 50, }}>
                                                    <h5 style={{ fontSize: 14 }}>{index + 1}. {item?.question}</h5>
                                                    <h6 style={{ color: THEME.greyish, fontSize: 14, marginTop: 10 }}>{item?.answer}</h6>
                                                </div>

                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }



                        {selectedInfo?.diagnose_qna_details.length <= 0 &&
                            <div className="card p-4 mt-4">
                                <h4>Picture Based Diagnosis</h4>
                                <h6 style={{ color: THEME.greyish }}>{selectedInfo?.condition_details?.name}</h6>

                                <div className="row m-0 mt-3">
                                    {selectedInfo?.diagnose_general_qna_details?.map((item, index) => {
                                        return (
                                            <div className="card p-3 col-3 shadow-sm" style={{ backgroundColor: '#f7f7f7' }}>
                                                <h5 style={{ fontSize: '14px !important' }}>{index + 1}. {item?.question}</h5>
                                                <h6 style={{ color: THEME.greyish }}>{item?.answer}</h6>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </>
                    : <>
                        {!selected.state ?
                            <div className="pr-4 client-section">
                                <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                                    <img src={Icons.QUESTIONICON} style={{ width: 25, margin: 'auto', }} /></div>
                                <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Clients</h3>
                                <p className="text-center">This is the admin panel clients</p>
                                <input className="text-center mt-3" style={{ margin: 'auto', display: "block", width: 400, height: 60 }} placeholder="Search Name" value={search} onChange={e => setsearch(e.target.value)} />
                                <div className="row m-0">
                                </div>
                                <div className="pt-3" />

                                <div className="w-100">
                                    <TabularItem data={CLIENTDATA} dataset={dataset} onOpen={item => handleOpenClient(item)} />
                                </div>
                            </div>
                            :
                            <>
                                <div
                                    onClick={() => setselected({
                                        state: false,
                                        data: []
                                    })}
                                    className="row align-item-center m-0 mt-4">
                                    <img src={Icons.BACKICON} />
                                    <h4 className="mb-0 ml-2">Back</h4>
                                </div>

                                <div className="client-card mt-3 pt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            {responseobj.state && <div className="p-4"><Response mode={responseobj.mode} message={responseobj.message} /><div className="mt-2 mb-2" /></div>}
                                            <div className="border-right p-3">
                                                {emailObj.state ?
                                                    <>
                                                        <h3 className="mb-1 mt-2">Compose Email</h3>
                                                        <h6 style={{ fontSize: 14 }} className="mb-1 mt-3">Email Address *</h6>
                                                        <input className='q-input w-100' onChange={e => setemailObj({ ...emailObj, email: e.target.value })} value={emailObj?.email || selected?.data?.email} placeholder='Email Address' />
                                                        <h6 style={{ fontSize: 14 }} className="mb-1 mt-3">Subject *</h6>
                                                        <input className='q-input w-100 ' onChange={e => setemailObj({ ...emailObj, subject: e.target.value })} value={emailObj?.subject} placeholder='Subject' />
                                                        <h6 style={{ fontSize: 14 }} className="mb-1 mt-3">Message *</h6>
                                                        <textarea className="q-input w-100" value={emailObj?.message}
                                                            onChange={e => setemailObj({ ...emailObj, message: e.target.value })}
                                                            rows={4}
                                                        ></textarea>
                                                        <Button
                                                            onClick={() => handleSendEmail()}
                                                            className="primary-btn  mt-3">
                                                            Send Email
                                                        </Button>
                                                        <Button
                                                            onClick={() => setemailObj({
                                                                ..._initialEmail,
                                                            })}
                                                            className="primary-btn  mt-3"
                                                            style={{ background: 'transparent', border: 'none', color: '#000' }}
                                                        >
                                                            Close
                                                        </Button>
                                                    </>
                                                    :
                                                    <div className="text-center">
                                                        <div className="pt-5 mt-4" />
                                                        <img src={selected?.data?.profile_picture?.split('?')[0]} className="image"
                                                            style={{ width: 100, height: 100, borderRadius: 100, backgroundColor: '#efefef', margin: 'auto' }}
                                                        />
                                                        <h4 className="mt-2">{selected?.data?.first_name + ' ' + selected?.data?.last_name}</h4>
                                                        <h6>Client</h6>
                                                        <Button
                                                            onClick={() => setemailObj({
                                                                ..._initialEmail,
                                                                email: selected?.data?.email,
                                                                state: true,
                                                            })}
                                                            className="primary-btn  mt-3">
                                                            Send Email
                                                        </Button>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="col-md-8">
                                            <div className="pt-5" />
                                            <h5 className="primary-colored mt-2">Personal Information

                                            </h5>
                                            <div className="row m-0 mt-3">
                                                <div className="col-md-3">
                                                    <h6 className="mt-2">Height</h6>
                                                    <h6 className="medium light-colored mt-2">{selected?.data?.clientprofile?.height}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6 className="mt-2">Weight</h6>
                                                    <h6 className="medium light-colored mt-2">{selected?.data?.clientprofile?.weight}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6 className="mt-2">Gender</h6>
                                                    <h6 className="medium light-colored mt-2">{selected?.data?.clientprofile?.gender == 1 ? 'Male' : 'Female'}</h6>
                                                </div>

                                                <div className="col-md-3">
                                                    <h6 className="mt-2">Date of Birth</h6>
                                                    <h6 className="medium light-colored mt-2">{selected?.data?.clientprofile?.dob}</h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 m-0">
                                                    <div className="pt-5" />
                                                    <h5 className="primary-colored mt-2">Diagnose Info

                                                    </h5>
                                                    <div className="row m-0 mt-3 mr-2 mb-4">
                                                        {diagnosesInfo.map(item => {
                                                            return (
                                                                <div style={{ cursor: 'pointer' }} onClick={() => setselectedInfo(item)} className="col-md-4 shadow-md p-3 border">
                                                                    <h6 className="mt-2 mb-1 font-bold">Diagnoses Condition</h6>
                                                                    <hr />
                                                                    <h6 className="medium light-colored text-sm mt-2 mb-1"><span className="font-bold">Diagnosis Date:</span> {moment(item.created)?.format('DD-MM-YYYY')}</h6>
                                                                    <hr />
                                                                    <h6 className="medium light-colored text-sm mt-2"><span className="font-bold">Conditions:</span> {item?.condition_details?.map(item => item.name)?.toString()}</h6>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>

                        }

                    </>}

            </div>
        </div >
    );
}

const ClientsScreen = HOC(Clients, 2);

export default ClientsScreen;







const ClientScreens = (props) => {

}


// function ClientScreens(props){
//     const [count, setcount] = useState(0); 

//     useEffect(() => {
//         setcount(count+10);
//     },[])

//     useEffect(() => {

//         if(count != 0){
//             if(count > 20){
//                 setcount(0);
//             }
//         }

//     },[count])

//     return(
//         <div>
//             <h5>{count}</h5>
//             <button onClick={() => setcount(count+1)}></button>
//         </div>
//     );

// }


// Life Cycle:

// Constructro
// Render
// DidMount
// DidUpdate / WillUpdate
// UnMount