import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Header, HOC, HomeItem } from "../components";
import { HOMEOPTIONS } from "../utils/_dataset";

const AppAnalytics = (props) => {
    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pt-5 pr-4 analytics-container">
                    <h3 >App Analytics</h3>
                    <div className="pt-3" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="card p-4">
                                <h6 >Total Usage Time</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="213 Hours" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">Amount of Active Users</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="2,398400 K+" className="input" />
                                </InputGroup>
                                <h6 className="mt-4">Most Used State</h6>
                                <InputGroup className="input-container">
                                    <Form.Control placeholder="Texas" className="input" />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AppAnalyticsScreen = HOC(AppAnalytics, 5);

export default AppAnalyticsScreen;