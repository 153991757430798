import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import CreatableSelect from 'react-select/creatable';
import { _call } from "../utils/_api";
import { THEME } from "../utils/_constant";
import _ from 'lodash';
import { QUESTIONDATA, SCOREOPTIONS, SYMPTOMSDATA } from "../utils/_dataset";
import { CircularProgress, Select } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ConditionQNA from "../components/Dialog/_conditionqna.dialog";

const _qnsInfoInitial = {
    mandatory: true,
    sorting_number: '',
    multi: false
}

const QuestionCE = (props) => {
    const location = useLocation();
    const data = location.state.data || [];
    const paindata = location.state.paindata || [];
    const navigate = useNavigate();

    const [selected, setselected] = useState(2);
    const [loader, setloader] = useState(false);
    const [qnsInfo, setqnsInfo] = useState(_qnsInfoInitial);
    const [painInfo, setpainInfo] = useState(paindata);
    const [answer, setanswer] = useState('');
    const [sort, setsort] = useState('');
    const [conditions, setconditions] = useState([]);
    const [selectedconditions, setselectedconditions] = useState([]);
    const [answers, setanswers] = useState([]);
    const [selectedpainInfo, setselectedpainInfo] = useState('');
    const [painoptions, setpainoptions] = useState([]);
    const [conditionsInfo, setconditionsInfo] = useState({
        state: false, data: []
    })
    const [addAnswerInfo, setaddAnswerInfo] = useState({
        state: false, data: []
    })
    const [question, setquestion] = useState('');
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: '',
        mode: 0
    });



    useEffect(() => {
        handleData();
    }, [data]);

    const handleData = async () => {
        setquestion(data?.tb1?.name);
        setqnsInfo({ ...qnsInfo, sorting_number: data?.tb2?.name, multi: data?.tb4?.name == 'False' ? 0 : 1 });

        const _getcondition = await _call(`api/v1/condition/?pain_category=${data?.selectedcategories}`, 'GET');
        setconditions(_getcondition.data);
        var selectedcategories = painInfo.find(x => x.id == data?.selectedcategories);
        handleSelectPain(selectedcategories.id);
        handleGetAnswers()
    }

    const handleGetAnswers = async () => {

        try {
            setloader(true);
            const _getdata = await _call(`api/v1/answer/?question=${data.id}`, 'GET');
            const _data = _getdata.data;
            setanswers(_data)
            setloader(false);

        } catch (err) {
            console.log(err)
            setloader(false);
        }
    };

    const handleSaveAnswer = async () => {

        if (sort == '' || answer == '' || _.isEmpty(selectedconditions)) {
            alert("Please complete all the required details")
            return;
        }

        var answersclone = [...answers];

        try {
            setloader(true);
            const obj = {
                answer: answer,
                question: data.id,
                condition: selectedconditions.map(item_ => item_.id),
                sorting_number: sort
            }
            const _createanswer = await _call('api/v1/answer/', 'POST', obj);
            const _ansdata = _createanswer.data;
            answersclone.push({
                text: _ansdata.answer,
                index: answersclone.length,
                item: _ansdata,
                state: true,
                conditions: _ansdata.condition,
                sort: 0
            });
            setloader(false);
            setanswers(answersclone);
            setanswer('');
            setresponseobj({
                mode: 1,
                message: 'Successfully updated',
                state: true
            })
        } catch (err) {
            setloader(false)
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }


    }

    const handleSave = async (type) => {

        try {
            setloader(true)
            const _dataset = {
                question,
                mandatory: qnsInfo.mandatory,
                sorting_number: parseInt(qnsInfo.sorting_number),
                allow_multi_select: qnsInfo.multi,
                sorting_number: qnsInfo.sorting_number
            }

            const _updateqna = await _call(`api/v1/question/${data.id}/`, 'PATCH', _dataset);
            setloader(false)
            console.log(_dataset)
            setresponseobj({
                mode: 1,
                message: 'Successfully updated',
                state: true
            })

        } catch (err) {
            console.log(err)
            setloader(false)
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }

        // const _createquestion = await _call('api/v1/question/', 'POST', _dataset);
        // const _qid = _createquestion.data.id;

        // var _answersInfo = [];

        // var _answersInfo = await Promise.all(answers.map(async item => {
        //     if (item.state) {
        //         const obj = {
        //             answer: item.text,
        //             question: _qid,
        //             condition: item.conditions.map(item_ => item_.id),
        //         }
        //         const _createanswer = await _call('api/v1/answer/', 'POST', obj);
        //         _answersInfo.push(_createanswer.data);
        //     }
        // }))

        // await Promise.all([_answersInfo]);
        // setanswer('');
        // setselectedconditions([]);
        // setqnsInfo(_qnsInfoInitial);
        // setquestion('');
        // setselectedpainInfo([]);
        // setloader(false);
        // setresponseobj({
        //     mode: 1,
        //     message: 'Successfully created',
        //     state: true
        // })



    }


    useEffect(() => {
        if (responseobj.state) {
            setTimeout(() => {
                setresponseobj({ state: false })
            }, 4000)
        }
    }, [responseobj]);


    const handleSelectPain = (id) => {
        var findselected = painInfo.filter(x => x.id == id);
        setselectedpainInfo(findselected)
        handleGetConditions(findselected[0]);
    }

    const handleGetConditions = async (item) => {
        try {
            const _getcondition = await _call(`api/v1/condition/?pain_category=${item.id}`, 'GET');
            setconditions(_getcondition.data.map(item => { item.value = item.name; item.label = item.name; item.id = item.id; return item }));

        } catch (err) {
            console.log(err);
        }
    }

    const handleSetAnswer = (item, key, val) => {
        var answerclone = [...answers];
        const fIndex = answerclone.findIndex(x => x.id == item.id);
        answerclone[fIndex][key] = val;
        setanswers(answerclone);
    }

    const handleChangeConditionScoreInfo = (item, key, value, condition) => {
        var answerclone = [...answers];
        const fIndex = answerclone.findIndex(x => x.id == item.id);
        var __index = answerclone[fIndex].condition_score?.findIndex(x => x == condition);
        answerclone[fIndex].condition_score[__index][key] = value;
        setanswers(answerclone)
    }

    const handleReturnConditions = (item) => {
        const condition = item.item.condition;
        var filterdata = [];
        conditions.map(item => {
            var ifExist = condition.filter(x => x == item.id);
            if (!_.isEmpty(ifExist)) {

                filterdata.push({
                    value: item.name,
                    label: item.name,
                    id: item.id
                })

            }
            return item;
        });
        return filterdata;
    }


    const handleDelete = async (type) => {
        setloader(true);
        try {

            const _deleteqna = await _call(`api/v1/question/${data.id}/`, 'DELETE');
            console.log(_deleteqna);
            navigate('/question');
            setloader(false);

        } catch (err) {
            console.log(err);
            setloader(false);
        }

    }


    const handleDeleteAnswer = async (item) => {

        var answersclone = [...answers];
        setloader(true);
        try {

            const _deleteans = await _call(`api/v1/answer/${item.id}/`, 'DELETE');
            console.log(_deleteans);
            setloader(false);

            answersclone = answersclone.filter(x => x != item);
            setanswers(answersclone);


            setresponseobj({
                mode: 1,
                message: 'Successfully deleted',
                state: true
            })
        } catch (err) {
            setloader(false)
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }
    }

    const handleUpdateAnswer = async (item) => {

        try {

            const _dataset = {
                answer: item.answer,
                question: data.id,
                condition_score: item.condition_score?.map(_item => {
                    return { condition: parseInt(_item?.condition), score: parseInt(_item?.score) }
                }),
                sorting_number: item.sorting_number
            }


            const _updateanswer = await _call(`api/v1/answer/${item.id}/`, 'PATCH', _dataset);
            setloader(false);
            setresponseobj({
                mode: 1,
                message: 'Successfully updated',
                state: true
            })

        } catch (err) {
            setloader(false)
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }

    }

    const onDelete = async (item, condition) => {

        try {

            var __answers = [...answers]
            var __Index = answers?.findIndex(x => x.id == item.id);
            var __conditions = __answers[__Index].condition_score?.filter(x => x != condition)

            const _dataset = {
                condition_score: __conditions
            }

            const _updateanswer = await _call(`api/v1/answer/${item.id}/`, 'PATCH', _dataset);

            __answers[__Index].condition_score = __conditions
            setanswers(__answers)
            setresponseobj({
                mode: 1,
                message: 'Deleted Successfully',
                state: true
            })
        } catch (err) {
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }

    }


    return (
        <div className="mytmg-container">
            <div className="p-5">

                <div className="pt-3" />

                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                <div
                    onClick={() => navigate('/question')}
                    className="row align-item-center m-0 mt-2" style={{ cursor: 'pointer' }}>
                    <img src={Icons.BACKICON} className="ml-2" />
                    <h4 className="mb-0 ml-2">Back</h4>
                </div>


                <div className="row m-0 mt-2 pt-4">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h5 className="flexfull mb-0">Edit Question</h5>
                            <p style={{ color: '#afafaf', fontSize: 12 }}>Edit single and multiple answered question</p>
                            {responseobj.state && <><Response mode={responseobj.mode} message={responseobj.message} /><div className="mt-2 mb-2" /></>}
                            <div className="mt-1" />

                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Question *</h6>
                            <input className='q-input' value={question} onChange={e => setquestion(e.target.value)} placeholder='Type your question here' />
                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Pain Category *</h6>
                            <input className='q-input' value={selectedpainInfo[0]?.category_name} disabled placeholder='Pain category' />
                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Filters</h6>
                            <div className="row m-0">
                                <div className="col-md-6 p-0">
                                    <input className='q-input w-100' value={qnsInfo.sorting_number} onChange={e => setqnsInfo({
                                        ...qnsInfo,
                                        sorting_number: e.target.value
                                    })} placeholder='Sort By' />
                                </div>
                                <div className={selected == 1 ? "col-md-6 p-0" : "col-md-3 p-0"}>
                                    <Button
                                        onClick={() => setqnsInfo({
                                            ...qnsInfo,
                                            mandatory: !qnsInfo.mandatory
                                        })}
                                        className={qnsInfo.mandatory ? "checkedbox" : "checkedbox excep"}
                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                    >Is this Mandatory?</Button>
                                </div>

                                <div className={"col-md-3 p-0"}>
                                    <Button
                                        onClick={() => setqnsInfo({
                                            ...qnsInfo,
                                            multi: !qnsInfo.multi
                                        })}
                                        className={qnsInfo.multi ? "checkedbox" : "checkedbox excep"}
                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                    >Multiple Selection?</Button>
                                </div>

                            </div>



                            <div className="flex flex-row items-center mt-4 justify-end">
                                <button
                                    onClick={() => {
                                        setaddAnswerInfo({ state: true, data: [] })
                                    }}
                                    className="bg-teal-500 w-44 h-12 text-white mr-2">
                                    Add New Answer
                                </button>
                                <Button
                                    onClick={() => handleSave()}
                                    className="bg-blue-500 w-44 h-12 text-white mr-2">
                                    Update</Button>
                                <Button
                                    onClick={() => {
                                        const answer = window.confirm('Are you sure');
                                        if (answer) {
                                            handleDelete()
                                        }
                                    }}
                                    className="bg-red-500 w-44 h-12 text-white">
                                    Delete</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card p-4" style={{ justifyContent: 'center' }}>
                            <>
                                <div className="row">
                                    {!_.isEmpty(answers) && answers.map((item, index) => {
                                        return (
                                            <div key={index} className="col-md-12 mt-4">
                                                <h6 style={{ fontSize: 14 }} className="mb-2 mt-4">Answer ({index + 1})</h6>

                                                <input className='q-input w-100' value={item.answer} onChange={e => handleSetAnswer(item, 'answer', e.target.value)}
                                                    placeholder='Type your answer here' />
                                                <h6 style={{ fontSize: 14 }} className="mb-2 mt-4">Sort</h6>
                                                <input className='q-input w-100' type="number" value={item.sorting_number} onChange={e => handleSetAnswer(item, 'sorting_number', e.target.value)}
                                                    placeholder='Type your sort number here' />
                                                <div className="mt-3" />
                                                <h6 style={{ fontSize: 14 }} className="mb-1">Conditions</h6>
                                                {item?.condition_score?.map(condition => {
                                                    return <div className="flex flex-row">
                                                        <select className='q-input w-100 mt-2 mr-2'
                                                            onChange={e => handleChangeConditionScoreInfo(item, 'condition', e.target.value, condition)}
                                                            value={condition?.condition}>
                                                            {conditions?.map(d => <option value={d.id}>{d.name}</option>)}
                                                        </select>
                                                        <select
                                                            onChange={e => handleChangeConditionScoreInfo(item, 'score', e.target.value, condition)}
                                                            className='q-input w-100 mt-2  mr-2' value={condition?.score}>
                                                            {SCOREOPTIONS?.map(d => <option value={d}>{d}</option>)}
                                                        </select>
                                                        <button
                                                            onClick={() => onDelete(item, condition)}
                                                            className="bg-red-500 w-44 h-14 text-xs mt-2 text-white">
                                                            Remove
                                                        </button>
                                                    </div>
                                                })}

                                                <div className="flex flex-row items-center mt-4 justify-end">
                                                    <button
                                                        onClick={() => {
                                                            setconditionsInfo({ state: true, data: item })
                                                        }}
                                                        className="bg-teal-500 w-44 h-12 text-white">
                                                        Add
                                                    </button>
                                                    <button
                                                        onClick={() => handleUpdateAnswer(item)}
                                                        className="bg-blue-500 w-44 h-12 text-white">
                                                        Update
                                                    </button>

                                                    <button
                                                        onClick={() => handleDeleteAnswer(item)}
                                                        className="bg-red-500 w-44 h-12 text-white">
                                                        Delete
                                                    </button>
                                                </div>
                                                {/* <div className="col-md-12 m-0 p-0">
                                                    <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                                    <Button
                                                        onClick={() => handleUpdateAnswer(item)}
                                                        className="checkedbox"
                                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                                    >Update</Button>
                                                </div>
                                                <div className="col-md-12 m-0 p-0">
                                                    <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                                    <Button
                                                        onClick={() => handleDeleteAnswer(item)}
                                                        className="checkedbox"
                                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                                    >Delete</Button>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>

                        </div>
                    </div>

                </div>
            </div>
            <Dialog.ConditionQNA conditions={conditions} state={conditionsInfo?.state}
                data={conditionsInfo?.data}
                setanswers={setanswers}
                answers={answers}
                onResultClose={result => {
                    setresponseobj({ state: true, mode: result, message: result == 0 ? 'Some Problem Occured' : ' Updated Successfully' })
                    setconditionsInfo({ state: false })
                }}
                onClose={() => setconditionsInfo({ state: false })} />


            <Dialog.QAnswer conditions={conditions} state={addAnswerInfo?.state}
                data={addAnswerInfo?.data}
                setanswers={setanswers}
                answers={answers}
                questionId={data.id}
                onResultClose={result => {
                    setresponseobj({ state: true, mode: result, message: result == 0 ? 'Some Problem Occured' : ' Updated Successfully' })
                    setaddAnswerInfo({ state: false })
                }}
                onClose={() => setaddAnswerInfo({ state: false })} />


        </div >
    );
}

const EditQuestionCEScreen = HOC(QuestionCE, 1);

export default EditQuestionCEScreen;