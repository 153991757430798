import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import CreatableSelect from 'react-select/creatable';
import { _call } from "../utils/_api";
import { THEME } from "../utils/_constant";
import _ from 'lodash';
import { QUESTIONDATA, SCOREOPTIONS, SYMPTOMSDATA } from "../utils/_dataset";
import { CircularProgress, Icon, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";

const _qnsInfoInitial = {
    mandatory: true,
    sorting_number: '',
    multi: false
}

const __initialConditions = {
    condition: [],
    score: 1,
    id: _.uniqueId()
}

const QuestionCE = (props) => {
    const [selected, setselected] = useState(2);
    const [loader, setloader] = useState(false);
    const [qnsInfo, setqnsInfo] = useState(_qnsInfoInitial);
    const [painInfo, setpainInfo] = useState([]);
    const [answer, setanswer] = useState('');
    const [conditions, setconditions] = useState([]);
    const [selectedconditions, setselectedconditions] = useState([]);
    const [answers, setanswers] = useState([]);
    const [selectedpainInfo, setselectedpainInfo] = useState('');
    const [painoptions, setpainoptions] = useState([]);
    const [score, setscore] = useState(1);
    const [onSelectedConditions, setonSelectedConditions] = useState([__initialConditions]);
    const [question, setquestion] = useState('');
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: '',
        mode: 0
    });



    const navigate = useNavigate();

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            // setloader(true);
            const _data = await _call('api/v1/pain-category/', 'GET');
            setpainoptions(_data.data.map(item => {
                item.label = item.category_name;
                item.value = item.category_name;
                item.id = item.id;
                return item;
            }));

            setpainInfo(_data.data);
            setloader(false);
        } catch (err) {
            console.log(err);
            setloader(false);
        }
    }


    const handleSave = async (type) => {
        if (question == '' && _.isEmpty(selectedpainInfo) && selected == 1) {
            setresponseobj({
                mode: 0,
                message: 'Please fill all the required fields',
                state: true
            })
            return;
        }

        if (question == '' && _.isEmpty(selectedpainInfo) && selected == 2 && _.isEmpty(answers)) {
            setresponseobj({
                mode: 0,
                message: 'Please fill all the required fields',
                state: true
            })
            return;
        }
        setloader(true);
        var qnaarr = selectedpainInfo.map(item => item.id);
        if (selected == 1) {
            const _dataset = {
                question,
                mandatory: qnsInfo.mandatory,
                sorting_number: parseInt(qnsInfo.sorting_number),
                pain_category: qnaarr
            }
            console.log(_dataset)
            try {

                const _createquestion = await _call('api/v1/general-question/', 'POST', _dataset);
                setqnsInfo(_qnsInfoInitial);
                setquestion('');
                setselectedpainInfo([]);
                setanswers([]);
                setloader(false);
                setresponseobj({
                    mode: 1,
                    message: 'Successfully created',
                    state: true
                })
            } catch (err) {
                console.log(err);
                setloader(false);
                setresponseobj({
                    mode: 0,
                    message: 'Some problem occured',
                    state: true
                })
            }
        } else {

            const _dataset = {
                question,
                mandatory: qnsInfo.mandatory,
                sorting_number: parseInt(qnsInfo.sorting_number),
                pain_category: selectedpainInfo[0].id,
                allow_multi_select: qnsInfo.multi,
            }

            const _createquestion = await _call('api/v1/question/', 'POST', _dataset);
            const _qid = _createquestion.data.id;

            var _answersInfo = [];

            var _answersInfoPr = await Promise.all(answers.map(async item => {
                if (item.state) {
                    const obj = {
                        answer: item.text,
                        question: _qid,
                        condition_score: item.conditions.map(item => {
                            return {
                                condition: item?.condition?.id,
                                score: item?.score
                            }
                        }),
                    }

                    const _createanswer = await _call('api/v1/answer/', 'POST', obj);
                    _answersInfo.push(obj);
                }
            }))

            await Promise.all([_answersInfoPr]);

            setanswer('');
            setselectedconditions([]);
            setqnsInfo(_qnsInfoInitial);
            setquestion('');
            setselectedpainInfo([]);
            setloader(false);
            setresponseobj({
                mode: 1,
                message: 'Successfully created',
                state: true
            })
        }


    }


    useEffect(() => {
        if (responseobj.state) {
            setTimeout(() => {
                setresponseobj({ state: false })
            }, 4000)
        }
    }, [responseobj]);


    const handleSelectPain = (id) => {
        var findselected = painInfo.filter(x => x.id == id);
        setselectedpainInfo(findselected)
        setanswers([])
        setanswer('')
        setscore(1)
        setselectedconditions([])
        handleGetConditions(findselected[0]);
    }

    const handleGetConditions = async (item) => {
        try {
            const _getcondition = await _call(`api/v1/condition/?pain_category=${item.id}`, 'GET');
            setconditions(_getcondition.data.map(item => { item.value = item.name; item.label = item.name; item.id = item.id; return item }));
            // setselectedconditions(_getcondition.data.map(item => { item.value = item.name; item.label = item.name; item.id = item.id; return item }));
        } catch (err) {
            console.log(err);
        }
    }

    const handleSaveAnswer = () => {
        var answersclone = [...answers];

        answersclone.push({
            text: answer,
            index: answersclone.length,
            state: true,
            conditions: onSelectedConditions?.map(item => {
                return {
                    score: item.score,
                    condition: conditions?.find(x => x.id == item?.condition)
                }
            }),
        });
        setanswers(answersclone);
        setonSelectedConditions([__initialConditions])
        setanswer('')
        setscore(1);
    }

    const renderConditions = (item) => {
        return item?.conditions.map(item => item.name).toString();
    }


    const handleToggleAnswer = (item) => {
        var answerscloneItem = [...answers];
        // var index = answerscloneItem.findIndex(x => x == item);
        // answerscloneItem[index].state = !answerscloneItem[index].state;
        setanswers(answerscloneItem?.filter(x => x.conditions?.id != item?.conditions?.id))
    }


    const onSaveConditionsInfo = (value, key, item) => {
        var __selectedConditionsInfo = [...onSelectedConditions];
        var Index = __selectedConditionsInfo?.findIndex(x => x.id == item?.id)
        console.log(Index, value, key)
        __selectedConditionsInfo[Index][key] = value;
        setonSelectedConditions(__selectedConditionsInfo)
    }


    return (
        <div className="mytmg-container">
            <div className="p-5">

                <div className="pt-3" />
                {/* <Header /> */}
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                <div
                    onClick={() => navigate('/question')}
                    className="row align-item-center m-0 mt-2" style={{ cursor: 'pointer' }}>
                    <img src={Icons.BACKICON} className="ml-2" />
                    <h4 className="mb-0 ml-2">Back</h4>
                </div>


                <div className="row m-0 mt-2 pt-4">
                    <div className="col-md-8">
                        <div className="card p-4">
                            <h5 className="flexfull mb-0">Create Question</h5>
                            <p style={{ color: '#afafaf', fontSize: 12 }}>Create single and multiple answered question</p>
                            {responseobj.state && <><Response mode={responseobj.mode} message={responseobj.message} /><div className="mt-2 mb-2" /></>}
                            <div className="mt-2" />
                            <div className="row m-0">
                                <Button onClick={() => {
                                    setselected(2);
                                    setselectedpainInfo([])
                                }} className={selected == 2 ? 'toggle-tab-bar active' : 'toggle-tab-bar'}>Particular Question / Answer</Button>
                                <Button onClick={() => {
                                    setselectedpainInfo(painoptions)
                                    setselected(1);

                                }}
                                    className={selected == 1 ? 'toggle-tab-bar ml-3 active' : 'toggle-tab-bar ml-3'}>General Question / Answer</Button>

                            </div>

                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Question *</h6>
                            <input className='q-input' value={question} onChange={e => setquestion(e.target.value)}
                                placeholder='Type your question here' />
                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Pain Category *</h6>
                            {selected == 1 ?
                                <CreatableSelect
                                    isClearable
                                    onChange={e => setselectedpainInfo(e)}
                                    isMulti={true}
                                    placeholder="Select Pain Category"
                                    options={painoptions}
                                    className="m-0 focus:outline-none"
                                    defaultValue={selectedpainInfo}
                                /> :
                                <select className='q-input'
                                    onChange={e => handleSelectPain(e.target.value)}>
                                    <option>Select Pain Category</option>
                                    {painoptions.map(item => <option value={item.id}>{item.category_name}</option>)}
                                </select>
                            }

                            <div className="mt-4" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Filters</h6>
                            <div className="row m-0">
                                <div className="col-md-6 p-0">
                                    <input className='q-input w-100 hover:outline-none' value={qnsInfo.sorting_number} onChange={e => setqnsInfo({
                                        ...qnsInfo,
                                        sorting_number: e.target.value
                                    })} placeholder='Sort By' />
                                </div>
                                <div className={selected == 1 ? "col-md-6 p-0" : "col-md-3 p-0"}>
                                    <Button
                                        onClick={() => setqnsInfo({
                                            ...qnsInfo,
                                            mandatory: !qnsInfo.mandatory
                                        })}
                                        className={qnsInfo.mandatory ? "checkedbox" : "checkedbox excep"}
                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                    >Is this Mandatory?</Button>
                                </div>

                                {selected == 2 &&
                                    <div className={"col-md-3 p-0"}>
                                        <Button
                                            onClick={() => setqnsInfo({
                                                ...qnsInfo,
                                                multi: !qnsInfo.multi
                                            })}
                                            className={qnsInfo.multi ? "checkedbox" : "checkedbox excep"}
                                            style={{ height: 60, width: '100%', fontSize: 14, }}
                                        >Multiple Selection?</Button>
                                    </div>
                                }

                            </div>

                            {selected == 2 &&
                                <>
                                    <div className="mt-4" />
                                    <h6 style={{ fontSize: 14 }} className="mb-1">Answer *</h6>
                                    <input className='q-input' value={answer} onChange={e => setanswer(e.target.value)} placeholder='Type your answer here' />
                                    <div className="mt-4" />
                                    {/* <h6 style={{ fontSize: 14 }} className="mb-1">Conditions *</h6> */}
                                    {onSelectedConditions?.map((item, index) => {
                                        return <div className="row m-0 mt-2" key={index}>
                                            <div className="col-md-6 m-0 p-0">
                                                <h6 style={{ fontSize: 14 }} className="mb-1">Condition - {index + 1}</h6>
                                                <select onChange={e => onSaveConditionsInfo(e.target.value, 'condition', item)} className='q-input w-full focus:outline-none'>
                                                    {conditions?.map((item, index) => {
                                                        return <option value={item.id}>{item?.label}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-md-2 m-0 p-0">
                                                <h6 style={{ fontSize: 14 }} className="mb-1">Score</h6>
                                                <select onChange={e => onSaveConditionsInfo(e.target.value, 'score', item)} className='q-input w-full focus:outline-none border-l' placeholder="Score" >
                                                    {SCOREOPTIONS.map(item => <option value={item}>{item}</option>)}
                                                </select>
                                                {/* <input className='q-input w-full focus:outline-none border-l' placeholder="Score" onChange={e => onSaveConditionsInfo(e.target.value, 'score', item)} /> */}
                                            </div>
                                            <div className="col-md-2 m-0 p-0">
                                                <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                                <Button
                                                    onClick={() => setonSelectedConditions([...onSelectedConditions, { ...__initialConditions, id: _.uniqueId() }])}
                                                    className="checkedbox"
                                                    style={{ height: 60, width: '100%', fontSize: 14, }}
                                                >Save</Button>
                                            </div>
                                            <div className="col-md-2 m-0 p-0">
                                                <h6 style={{ fontSize: 14 }} className="mb-1">&nbsp;</h6>
                                                <Button
                                                    onClick={() => {
                                                        setonSelectedConditions(onSelectedConditions?.filter(x => x.id != item?.id))
                                                    }}
                                                    className="rounded-none border-none bg-red-500"
                                                    style={{ height: 60, width: '100%', fontSize: 14, }}
                                                >Remove</Button>
                                            </div>
                                        </div>
                                    })}
                                    <Button
                                        onClick={() => handleSaveAnswer()}
                                        className="checkedbox mt-4"
                                        style={{ height: 60, width: '100%', fontSize: 14, }}
                                    >Save Answer</Button>
                                </>
                            }

                            <div className="mt-4">
                                <Button
                                    onClick={() => handleSave(1)}
                                    className="checkedbox teal"
                                    style={{ height: 60, width: '100%', fontSize: 14, }}
                                >Submit</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card p-4" style={{ justifyContent: 'center' }}>
                            <h5 className="mb-0">Question Display</h5>
                            <p style={{ color: '#afafaf', fontSize: 12 }}>Create single and multiple answered question</p>
                            <h6 style={{ fontSize: 14 }} className="mb-1 mt-4">Question</h6>
                            <p style={{ fontSize: 14, fontStyle: 'italic', color: '#afafaf', lineHeight: 1.3 }} className="mb-1">
                                {question}
                            </p>
                            <div className="mt-3" />
                            <h6 style={{ fontSize: 14 }} className="mb-1">Pain Categories</h6>
                            <div className="row m-0">
                                {!_.isEmpty(selectedpainInfo) && selectedpainInfo.map(item => {
                                    return (
                                        <Button className="transparent-btn excep mr-2">{item.category_name}</Button>
                                    );
                                })}
                            </div>
                            <div className="mt-3" />
                            <p style={{ fontSize: 14, color: '#000', lineHeight: 1.3 }} className="mb-1">
                                -  Sorting at: {qnsInfo.sorting_number}
                            </p>
                            <p style={{ fontSize: 14, color: '#000', lineHeight: 1.3 }} className="mb-1">
                                -  It is {qnsInfo.mandatory ? 'mandatory' : 'not mandatory'}
                            </p>
                            {selected == 2 &&

                                <p style={{ fontSize: 14, color: '#000', lineHeight: 1.3 }} className="mb-1">
                                    -  It is {qnsInfo.multi ? 'Multiple Selection' : 'not Multiple Selection'}
                                </p>
                            }

                            {selected == 2 && <>
                                <div className="mt-3" />
                                <h6 style={{ fontSize: 14 }} className="mb-0">Answers</h6>
                                <div className="row m-0 h-[40vh] overflow-scroll">
                                    {!_.isEmpty(answers) && _.orderBy(answers, 'index', 'desc').map((item, index) => {
                                        return (
                                            <div className={`min-w-[100px] min-h-[20px] border mt-2 relative cursor-pointer bg-slate-50 p-3 w-full`}>
                                                <button onClick={() => handleToggleAnswer(item)} className="absolute top-5 right-5 text-xs cursor-pointer">
                                                    <img src={Icons.CROSSICON} className="w-2 h-2" />
                                                </button>

                                                <div className="flex flex-row ">
                                                    <p className="text-slate-500 text-sm mr-4">{item.text}</p>
                                                </div>

                                                {item?.conditions?.map((condition, __) => {
                                                    return <div className="flex flex-row items-center w-full bg-white p-2 mt-2">
                                                        <div className="flex-1 mr-2">
                                                            <h4 className="text-sm">Condition</h4>
                                                            <p className="text-slate-500 text-sm">{condition.condition?.label}</p>
                                                        </div>

                                                        <div className="min-w-[100px]">
                                                            <h4 className="text-sm">Score</h4>
                                                            <p className="text-slate-500 text-sm">{condition.score}</p>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>}

                        </div>
                    </div>

                </div>
            </div>

        </div >
    );
}

const QuestionCEScreen = HOC(QuestionCE, 1);

export default QuestionCEScreen;