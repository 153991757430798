import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const Response = (props) => {
    const { mode, message } = props;

    if (mode == 0) {
        return <>
            <Alert severity="error" className="w-100">
                <AlertTitle>Error</AlertTitle>
                {message}
            </Alert>
        </>
    }

    if (mode == 1) {
        return <>
            <Alert severity="success" className="w-100">
                <AlertTitle>Success</AlertTitle>
                {message}
            </Alert>
        </>
    }
}

export default Response;