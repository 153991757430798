import ConditionModal from "./_condition.dialog";
import QuestionModal from "./_question.dialog";
import ContentModal from "./_content.dialog";
import QuestionCEModal from './_questionce.dialog';
import PictureModal from "./_content.dialog";
import ContentCategory from './_contentcategory';
import ContentEdit from './_contentedit.dialog';
import SpecialistDialog from './_specialist.dialog';
import ConditionQNA from "./_conditionqna.dialog";
import QAnswer from './_qanswer.dialog';

const Dialog = {
    Question: QuestionModal,
    Condition: ConditionModal,
    Content: ContentModal,
    QuestionCE: QuestionCEModal,
    Picture: PictureModal,
    ContentCategory: ContentCategory,
    ContentEdit: ContentEdit,
    Specialist: SpecialistDialog,
    ConditionQNA, QAnswer
}

export default Dialog;