import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, TableFooter } from "@mui/material";

const CuTabularComp = (props) => {
    const { columns, renderChildren, onIncrease } = props;
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor:'#444444'}}>
                        {columns.map(item => <TableCell align="left" style={{color:'#fff'}}>{item}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderChildren()}
                </TableBody>
                {/* <TableFooter className="w-100">
                    <button onClick={onIncrease} className='bg-blue-500 w-32 rounded-full text-white p-2 m-3 '>Next</button>
                </TableFooter> */}
            </Table>
        </TableContainer>
    );
}

export default CuTabularComp;