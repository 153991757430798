import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icons } from '../assets'
import _ from 'lodash'
import { THEME } from '../utils/_constant';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    paddingTop: '50px',
    height: 200,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fff',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};




const ImageUploader = (props) => {
    const { sendFiles, numberOfFiles, removeFile } = props;
    const [allfiles, setallfiles] = useState([]);
    const onDrop = React.useCallback(acceptedFiles => {
        // sendFiles(acceptedFiles);
        setallfiles(acceptedFiles)
    }, []);
    const { acceptedFiles, getRootProps, getInputProps, isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({ onDrop, maxFiles: numberOfFiles });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const files = allfiles.map(file => (
        <div style={{
            backgroundColor: '#f7f7f7',
            width: '100%',
            padding: 10,
            display: 'flex',
            marginTop: 5
        }}>
            <h4 style={{ flex: 1, fontSize: 10 }}>{file.path}</h4>
            <i
                className='icon-line-awesome-times-circle'
                onClick={() => setallfiles([]) || sendFiles([])}
                style={{ fontSize: 14 }}></i>
        </div>
        // <li key={file.path}>


        // </li>
    ));

    useEffect(() => {
        console.log('allfiles', allfiles)
        sendFiles(allfiles)
    }, [allfiles]);



    return (
        <section className="container p-0">
            <div {...getRootProps({ style })} className='mt-3 image-picker'>
                <input {...getInputProps()} />
                <div className='row' style={{ alignItems: 'center', justifyContent: 'center', marginTop: -25 }}>
                    <img src={Icons.COLOREDPLUSICON} style={{ width: 20, height: 20 }} />
                    <h6 className="medium" style={{ color: '#029ED8', marginLeft: 5, marginTop: 5 }}>Upload File</h6>
                </div>
            </div>
            <aside className='mt-2'>
                {files}
                {/* {!_.isEmpty(files) && } */}

            </aside>
        </section>
    );
}

export default ImageUploader;