import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.scss';
import {
  AppAnalyticsScreen, ClientAnalyticsScreen, ClientsScreen, ConditionScreen, ContentInfoScreen, CreatePictureQNA, ContentSection,
  EditQuestionCEScreen, HomePage, LoginScreen, OnboardingScreen, PictureQNA, QuestionCEScreen, QuestionsScreen, SymptomsScreen
} from './scenes';
import ContentLibraryScreen from './scenes/_contentlibrary';
import DiagnosisScreen from './scenes/_diagnosis';
import Membership from './scenes/_membership';
import DeleteAccountScreen from './scenes/_deleteAccount';

const PrivateRoutes = () => {
  let auth = localStorage.getItem('token');
  return (
    !auth ? <Navigate to="/login" replace /> : <Outlet />
  )
}


const App = (props) => {
  let auth = localStorage.getItem('token')
  return (
    // <ClientsScreen />
    <Router>
      <Routes>
        <Route element={auth ? <Navigate to="/" replace /> : <LoginScreen />} path="/login" />
        <Route element={<DeleteAccountScreen />} path="/delete-account" />
        <Route element={<PrivateRoutes />}>
          <Route element={<ClientAnalyticsScreen />} path="/client-analytics" />
          <Route element={<AppAnalyticsScreen />} path="/analytics" />
          <Route element={<ContentLibraryScreen />} path="/content" />
          <Route element={<ConditionScreen />} path="/condition" />
          <Route element={<EditQuestionCEScreen />} path="/edit-question" />
          <Route element={<Membership />} path="/membership" />
          <Route element={<OnboardingScreen />} path="/onboarding-information" />
          <Route element={<PictureQNA />} path="/picture-question" />
          <Route element={<ClientsScreen />} path="/clients" /> {/* Done */}
          <Route element={<SymptomsScreen />} path="/symptoms" />
          <Route element={<QuestionsScreen />} path="/question" />
          <Route element={<QuestionCEScreen />} path="/question-detail" />
          <Route element={<ContentInfoScreen />} path="/content-info" />
          <Route element={<DiagnosisScreen />} path="/diagnosis" />
          <Route element={<CreatePictureQNA />} path="/create-picture-flow" />
          <Route element={<ContentSection />} path="/content-section" />
          <Route element={<HomePage />} path="/home-page" />
        </Route>

        <Route path='*' element={<Navigate to='/home-page' />} />
      </Routes>
    </Router>
  );
}

export default App;