import React, { useState, useEffect, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { isEmpty } from 'lodash';
import Select from 'react-select';
import parse, { attributesToProps } from 'html-react-parser';
import { CircularProgress } from "@mui/material";
import { Dialog, Header, HOC, HomeItem, Response, ImageUploader } from "../components";
import TabularItem from "../components/_tabularitem";
import { useSingleDataHook } from '../utils/_hooks'
import { _call, _multipartCall } from "../utils/_api";
import { CONTENTSECTIONDATA } from "../utils/_dataset";
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

const _request = {
    url: 'api/v1/main-category/',
    method: 'GET'
};

const _painRequest = {
    url: 'api/v1/pain-category/',
    method: 'GET'
};

const _infoRequest = {
    url: 'api/v1/sub-category/?main_category=1',
    method: 'GET'
}

const _editorSetting = {
    height: 300,
    menubar: true,
    image_title: true,
    file_picker_types: 'file image media',
    plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste image code help wordcount",
    ],
    toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
    content_style:
        `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
}

const Component = (props) => {
    const [loader, setloader] = useState(false);
    const [__isLoading, __response, __error, onRefetch] = useSingleDataHook(_infoRequest);
    const [isLoading, response, error] = useSingleDataHook(_request);
    const [_isLoading, _response, _error] = useSingleDataHook(_painRequest);
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    });
    const [dialogstate, setdialogstate] = useState({
        state: false
    });
    const [editstate, seteditstate] = useState({
        state: false
    });
    const [tabularView, settabularView] = useState(true);
    const [selected, setselected] = useState(-1);
    const [selectedFile, setselectedFile] = useState([]);
    const [selectedPainId, setselectedPainId] = useState(-1);
    const [selectedConditionId, setselectedConditionId] = useState(-1);
    const [selectedContent, setselectedContent] = useState([]);
    const [conditions, setconditions] = useState([]);
    const [painInfo, setpainInfo] = useState([]);
    const [contentFileData, setcontentFileData] = useState([]);
    const [categories, setcategories] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [selectedUrl, setselectedUrl] = useState('');
    const [subCategory, setsubCategory] = useState('');
    const [contentDescription, setcontentDescription] = useState('');


    const editorRef = useRef(null);
    const _editorRef = useRef(null);

    useEffect(() => {
        if (!isEmpty(response)) {
            setcategories(response);
        }

        if (!isEmpty(_response)) {
            setpainInfo(_response);
        }

        if (!isEmpty(__response)) {
            handleShapeData();
        }

        if (isEmpty(__response)) {
            setdataset([])
        }

    }, [response, _response, __response]);


    const handleShapeData = () => {
        const _data = __response;
        var shape = [];

        _data?.map((item, index) => {
            const obj = {
                id: item?.id,
                tb1: {
                    name: item?.category_name
                },
                tb2: {
                    name: item?.condition_details[0]?.name,
                },
                tb3: {
                    name: moment(item?.created_at).format('DD-MM-YYYY'),
                },
                ...item,
            }
            shape.push(obj);
        });

        setdataset(shape);
    }


    const handleSave = async () => {
        if (subCategory == '' || selectedPainId == -1 || selectedConditionId == -1 || selected == -1) {
            setresponseobj({
                mode: 0,
                message: 'Please fill all information',
                state: true
            })
            return;
        }

        try {
            setloader(true);

            const _dataset = {
                content: editorRef?.current?.getContent(),
                main_category: selected,
                condition: [selectedConditionId],
                category_name: subCategory,
                url: selectedUrl,
            };

            const _createSubCategory = await _call('api/v1/sub-category/', 'POST', _dataset);
            console.log(_createSubCategory.data)
            setsubCategory('');
            setloader(false);
            setresponseobj({
                mode: 1,
                message: 'Successfully created',
                state: true
            });

        } catch (err) {
            setloader(false)
            console.log(err);
            setresponseobj({
                mode: 0,
                message: 'Some problem occured',
                state: true
            })
        }
    }

    const handleUpdateContent = (data) => {
        var _dataset = [...dataset];
        var fIndex = _dataset.findIndex(x => x.id == selectedContent.id);
        setselectedContent({ ...selectedContent, ...data });
        _dataset['tb1'].name = data.category_name;
        setdataset(_dataset);
        seteditstate({ state: false })
    }


    const handleSetContent = async (item) => {

        try {
            const _getContent = await _call(`api/v1/sub-category/${item.id}/`, 'GET');
            setselectedContent(_getContent.data);
            setcontentFileData(_getContent.data.content_media);
            console.log(_getContent.data)
        } catch (err) {
            console.log(err);
        }

    }

    const handleUpdate = async () => {

        try {
            setloader(true);
            var _dataset = [
                { key: 'description', value: _editorRef?.current?.getContent() },
                { key: 'sub_category', value: selectedContent.id },
            ];

            if (selectedFile) {
                _dataset.push({ key: 'file', value: selectedFile })
            }

            const _createRequest = await _multipartCall('api/v1/content-media/', 'POST', _dataset);
            var _contentFileData = [...contentFileData];
            _contentFileData.push(_createRequest.data);
            setcontentFileData(_contentFileData);
            setloader(false);
        } catch (err) {
            console.log(err);
            setloader(false);
        }

    }


    const handleSaveCategory = (data) => {
        var _category = [...categories];
        _category.push(data);
        setcategories(_category);
        setdialogstate({ ...dialogstate, state: false })
    }

    useEffect(() => {

        const handleGetConditions = async () => {
            console.log(selectedPainId)
            try {
                setloader(true);
                const _getConditions = await _call(`api/v1/condition/?pain_category=${selectedPainId}`, 'GET');
                setconditions(_getConditions.data);
                setloader(false);
            } catch (err) {
                console.log(err);
                setloader(false);
            }
        }
        if (selectedPainId != -1) handleGetConditions()
    }, [selectedPainId])


    const handleReturnContent = (file) => {
        const fileUri = file?.split('?')[0];
        const fileType = ['jpg', 'jpeg', 'png'];
        const extensionArr = fileUri.split('.');
        const extension = extensionArr[extensionArr.length - 1];


        if (fileType.includes(extension)) {
            return <img src={fileUri} className="w-100 h-48" />
        } else {

        }

        return <video width="220" height="220" style={{ backgroundColor: '#efefef' }} controls>
            <source src={fileUri} type="video/mp4" />
        </video>
    }

    const handleDeleteItem = async () => {

        try {
            const _deleteContent = await _call(`api/v1/sub-category/${selectedContent.id}/`, 'DELETE');
            console.log(_deleteContent.data);
            var _dataset = [...dataset];
            _dataset = _dataset.filter(x => x.id != selectedContent.id);
            setdataset(_dataset);
            setselectedContent([]);
        } catch (err) {
            console.log(err);
        }
    }

    const handleDelete = async (item) => {

        try {
            setloader(true);
            const _deleteFile = await _call(`api/v1/content-media/${item.id}/`, 'DELETE');
            console.log(_deleteFile.data);

            var _contentFileData = [...contentFileData];
            _contentFileData = _contentFileData.filter(x => x.id != item.id);
            setcontentFileData(_contentFileData);
            setloader(false);
            setresponseobj({
                mode: 1,
                message: 'Successfully deleted',
                state: true
            })
        } catch (err) {
            setloader(false);
            console.log(err);
        }
    }


    return (
        <div className="mytmg-container">

            <div className="p-5">
                {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}

                <div />
                <Header />
                {(loader || __isLoading) ? <div className='flex-1 flex justify-center items-center flex-col h-[100vh] w-100'>
                    <CircularProgress color="inherit" />
                    <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                </div> : <div className="pr-4">
                    <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                        <img src={Icons.CONTENTINFO} style={{ width: 25, margin: 'auto', }} /></div>
                    <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Content Section</h3>
                    <p className="text-center">This is the admin panel information section</p>

                    <div className="row m-0 mt-4" style={{ alignItems: 'center' }}>
                        {!isEmpty(selectedContent) && <img src={Icons.BACKICON} className="mr-3" onClick={() => setselectedContent([])} />}

                        {isEmpty(selectedContent) &&
                            <>
                                <select className="mr-2 selectinput"
                                    style={{ height: 50 }}
                                    onChange={e => {
                                        setselected(e.target.value)
                                        onRefetch(`api/v1/sub-category/?main_category=${e.target.value}`)
                                    }}>
                                    <option>Select any option</option>
                                    {categories.map(item => <option value={item.id}>{item.category_name}</option>)}
                                </select>
                                <Button
                                    onClick={() => settabularView(!tabularView)}
                                    className="header-btn mr-2"
                                    style={{ backgroundColor: '#252525', borderColor: '#fff', width: 200 }}
                                >
                                    <img src={Icons.WHITEPLUSICON} />
                                    <h4>{!tabularView ? "Tabular View" : "Create Content"}</h4>
                                </Button>
                                <Button
                                    onClick={() => setdialogstate({
                                        ...dialogstate,
                                        state: true
                                    })}
                                    className="header-btn"
                                    style={{ backgroundColor: '#252525', borderColor: '#fff', width: 200 }}
                                >
                                    <img src={Icons.WHITEPLUSICON} />
                                    <h4>Create Category</h4>
                                </Button>

                            </>
                        }
                    </div>
                    <div className="pt-3" />

                    {!tabularView ? <div className="w-100">
                        <div className="card p-4">
                            <h4 style={{ fontSize: 16 }}>Sub Category</h4>
                            <input className='q-input w-100 mt-2'
                                placeholder='Category Name'
                                style={{ borderLeft: '1px solid #fff', borderRight: '1px solid #fff', }}
                                value={subCategory} onChange={e => setsubCategory(e.target.value)}
                            />
                            <div className="mt-4" />
                            <h4 style={{ fontSize: 16 }}>Pain Category</h4>
                            <select className='q-input w-100 mt-2' value={selectedPainId} onChange={e => setselectedPainId(e.target.value)}>
                                {painInfo.map(item => <option value={item.id}>{item?.category_name}</option>)}
                            </select>
                            <div className="mt-4" />
                            <h4 style={{ fontSize: 16 }}>Condition</h4>
                            <select className='q-input w-100 mt-2' value={selectedConditionId} onChange={e => setselectedConditionId(e.target.value)}>
                                {conditions.map(item => <option value={item.id}>{item?.name}</option>)}
                            </select>
                            <h4 style={{ fontSize: 16 }} className="mt-4">URL</h4>
                            <input className='q-input w-100 mt-2'
                                placeholder='url1,url2,...'
                                style={{ borderLeft: '1px solid #fff', borderRight: '1px solid #fff', }}
                                value={selectedUrl} onChange={e => setselectedUrl(e.target.value)}
                            />
                            <div className="mt-4" />
                            <h4 style={{ fontSize: 16 }}>Content</h4>
                            <div className="mt-2" />
                            <Editor
                                onInit={(evt, editor) => {
                                    editorRef.current = editor

                                }}
                                apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                                init={{
                                    height: 400,
                                    menubar: true,
                                    image_title: true,
                                    extended_valid_elements: 'a[href]',
                                    file_picker_types: 'file image media',
                                    plugins: [
                                        "advlist autolink lists link image charmap print preview anchor",
                                        "searchreplace visualblocks code fullscreen",
                                        "insertdatetime media table paste image code help wordcount",
                                    ],
                                    toolbar:
                                        "undo redo | formatselect | " +
                                        "bold italic backcolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                        "removeformat | help",
                                    content_style:
                                        `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                                }}
                            />
                            <div className="mt-4" />
                            <Button
                                onClick={handleSave}
                                className="checkedbox"
                                style={{ height: 60, width: '100%', fontSize: 14, }}
                            >Save</Button>
                        </div>
                    </div> :
                        <div className="w-100">
                            {!isEmpty(selectedContent) ?
                                <div className="card w-100 p-4">
                                    <div className="grid grid-cols-3 gap-5">
                                        <div className="col-span-2">
                                            <h6>{selectedContent?.category_name}</h6>
                                        </div>
                                        <div className="flex flex-row items-center justify-end">
                                            <Button
                                                onClick={() => seteditstate({ ...editstate, state: true, })}
                                                className="header-btn"
                                                style={{ backgroundColor: 'teal', borderColor: '#fff', width: 100, height: 40 }}
                                            >
                                                <h4 style={{ fontSize: 14, marginLeft: 0 }}>Edit</h4>
                                            </Button>
                                            <Button
                                                onClick={handleDeleteItem}
                                                className="header-btn ml-4"
                                                style={{ backgroundColor: 'red', borderColor: '#fff', width: 100, height: 40 }}
                                            >
                                                <h4 style={{ fontSize: 14, marginLeft: 0 }}>Delete</h4>
                                            </Button>
                                        </div>
                                    </div>
                                    <h6 className="mt-2">URL: {selectedContent?.url}</h6>
                                    <div className="mt-4" />
                                    <hr />
                                    <div className="p-3">
                                        {parse(selectedContent?.content)}
                                    </div>
                                    <hr />
                                    <div className="mt-4" />
                                    <div className="grid lg:grid-cols-3 gap-5">
                                        <div className="col-span-2 grid grid-cols-2 gap-5">
                                            {contentFileData.map(item => {
                                                return <div className=" mt-2">
                                                    <div className="card shadow-sm p-3">
                                                        {item.file && handleReturnContent(item.file)}
                                                        <div className="mt-2" />
                                                        {parse(item?.description)}
                                                    </div>
                                                    <Button
                                                        onClick={() => handleDelete(item)}
                                                        className="header-btn mt-4"
                                                        style={{ backgroundColor: '#252525', borderColor: '#fff', width: '100%' }}
                                                    >
                                                        <h4>Delete</h4>
                                                    </Button>
                                                </div>
                                            })}
                                        </div>
                                        <div className="">
                                            <h6>Upload File</h6>
                                            <div style={{ marginTop: -6 }} />
                                            <ImageUploader
                                                sendFiles={e => setselectedFile(e[0])}
                                                numberOfFiles={1}
                                            />
                                            <div style={{ marginTop: 6 }} />

                                            <Editor
                                                onInit={(evt, editor) => {
                                                    _editorRef.current = editor
                                                }}
                                                apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                                                initialValue=""
                                                init={_editorSetting}
                                            />
                                            <Button
                                                onClick={handleUpdate}
                                                className="header-btn mt-4"
                                                style={{ backgroundColor: '#252525', borderColor: '#fff', width: '100%' }}
                                            >
                                                <h4>Submit</h4>
                                            </Button>
                                        </div>
                                    </div>
                                    {/* <div className="row m-0 mt-4">
                                        <div className="col-md-7 p-3">
                                            <div className="row">
                                                {contentFileData.map(item => {
                                                    return <div className="col-md-4 mt-2">
                                                        <div className="card shadow-sm p-3">
                                                            {handleReturnContent(item.file)}
                                                            <div className="mt-2" />
                                                            {parse(item?.description)}
                                                        </div>
                                                        <Button
                                                            onClick={() => handleDelete(item)}
                                                            className="header-btn mt-4"
                                                            style={{ backgroundColor: '#252525', borderColor: '#fff', width: '100%' }}
                                                        >
                                                            <h4>Delete</h4>
                                                        </Button>
                                                    </div>
                                                })}
                                            </div>

                                        </div>
                                        <div className="mt-3 card p-3 col-md-4  offset-md-1">
                                            <h6>Upload File</h6>
                                            <div style={{ marginTop: -6 }} />
                                            <ImageUploader
                                                sendFiles={e => setselectedFile(e[0])}
                                                numberOfFiles={1}
                                            />
                                            <div style={{ marginTop: 6 }} />
                                            <Editor
                                                onInit={(evt, editor) => {
                                                    _editorRef.current = editor
                                                }}
                                                apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                                                initialValue=""
                                                init={{
                                                    height: 300,
                                                    menubar: true,
                                                    image_title: true,
                                                    file_picker_types: 'file image media',
                                                    plugins: [
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste image code help wordcount",
                                                    ],
                                                    toolbar:
                                                        "undo redo | formatselect | " +
                                                        "bold italic backcolor | alignleft aligncenter " +
                                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                                        "removeformat | help",
                                                    content_style:
                                                        `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                                                }}
                                            />
                                            <Button
                                                onClick={handleUpdate}
                                                className="header-btn mt-4"
                                                style={{ backgroundColor: '#252525', borderColor: '#fff', width: '100%' }}
                                            >
                                                <h4>Submit</h4>
                                            </Button>
                                        </div>
                                    </div> */}

                                </div>
                                :
                                <TabularItem
                                    exceptional
                                    onOpen={item => {
                                        handleSetContent(item)
                                    }} data={CONTENTSECTIONDATA} dataset={dataset} />
                            }
                        </div>
                    }

                </div>}

            </div>
            <Dialog.ContentCategory state={dialogstate.state} onSave={(data) => handleSaveCategory(data)} onClose={() => setdialogstate({ ...dialogstate, state: false })} />
            <Dialog.ContentEdit state={editstate.state} data={selectedContent} onSave={(data) => {
                seteditstate({ ...dialogstate, state: false })
                handleUpdateContent(data)
            }} onClose={() => seteditstate({ ...dialogstate, state: false })} />
        </div >
    );
}

const ContentSection = HOC(Component, 8);

export default ContentSection;