import React, { useState, useEffect, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Icons } from "../assets";
import { Header, HOC, HomeItem, ImageUploader, Response } from "../components";
import { _call, _multipartCall } from "../utils/_api";
import parse, { attributesToProps } from 'html-react-parser';
import _ from 'lodash'
import { Editor } from '@tinymce/tinymce-react';
import { CircularProgress, Table } from "@mui/material";

const ContentLibrary = (props) => {
    const [type, settype] = useState(0)
    const [contentarr, setcontentarr] = useState([{
        heading: '',
        description: '',
        file: []
    }]);
    const [loader, setloader] = useState(false);
    const [onboardingInfo, setonboardingInfo] = useState([{
        heading: '',
        description: '',
        file: []
    }]);
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    });
    const [information, setinformation] = useState([]);
    const editorRef = useRef(null);



    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = async () => {

        try {
            const _getInformationContent = await _call('api/v1/information-page/', 'GET');
            console.log(_getInformationContent.data)
            setinformation(_getInformationContent.data)
        } catch (err) {
            console.log(err);
        }

    }


    const handleChange = (item, key, value) => {
        var contentarrclone = [...contentarr];
        const index = contentarrclone.findIndex(x => x === item);
        contentarrclone[index][key] = value;
        setcontentarr(contentarrclone);
    }

    const handleChangeOnboarding = (item, key, value) => {
        var contentarrclone = [...onboardingInfo];
        const index = contentarrclone.findIndex(x => x === item);
        contentarrclone[index][key] = value;
        setonboardingInfo(contentarrclone);
    }

    const handleAdd = () => {
        var contentarrclone = [...contentarr];
        contentarrclone.push({
            heading: '',
            description: '',
            file: []
        });
        setcontentarr(contentarrclone)
    }

    const handleAddOnboarding = () => {
        var contentarrclone = [...onboardingInfo];
        contentarrclone.push({
            heading: '',
            description: '',
            file: []
        });
        setonboardingInfo(contentarrclone)
    }



    const handleSubmit = async (item) => {
        const token = await localStorage.getItem('token');
        var contentarrclone = [...contentarr];
        const index = contentarrclone.findIndex(x => x === item);
        setloader(true)
        try {

            // const _dataset = {
            //     title: contentarrclone[index].heading,
            //     description: editorRef?.current?.getContent()
            // }

            var _dataset = [
                { key: 'title', value: contentarrclone[index].heading },
                { key: 'description', value: contentarrclone[index].description, },
            ];

            if (!_.isEmpty(contentarrclone[index].file)) {
                _dataset.push({
                    key: "files", value: contentarrclone[index].file
                })
            }




            const _createrequest = await _multipartCall('api/v1/information-page/', 'POST', _dataset, {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Token ' + token
            });

            contentarrclone[index] = {
                heading: '',
                description: '',
                file: []
            }
            setloader(false)
            setcontentarr(contentarrclone)

            setresponseobj({
                state: true,
                message: 'Successfully saved',
                mode: 1
            });

        } catch (err) {
            console.log(err.response)
            setresponseobj({
                state: true,
                message: 'Some problem occured',
                mode: 0
            })
            setloader(false)
        }
    }


    const handleSubmitOnboarding = async (item) => {
        const token = await localStorage.getItem('token');
        var contentarrclone = [...onboardingInfo];
        const index = contentarrclone.findIndex(x => x === item);

        try {

            const _dataset = {
                title: contentarrclone[index].heading,
                description: contentarrclone[index].description,
            }

            const _createrequest = await _call('api/v1/information-page/', 'POST', _dataset, {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Token ' + token
            });

            contentarrclone = [];

            setonboardingInfo(contentarrclone)

            setresponseobj({
                state: true,
                message: 'Successfully saved',
                mode: 1
            });

        } catch (err) {
            console.log(err.response)
            setresponseobj({
                state: true,
                message: 'Some problem occured',
                mode: 0
            })
        }
    }

    const handleOperation = async (type, item) => {

        if (type == 'status') {

            const _updateRequest = await _call(`api/v1/information-page/${item.id}/`, 'PATCH', {
                active: !item.active
            });

            var _dataset = [...information];
            const index = _dataset.findIndex(x => x.id == item.id);
            _dataset[index].active = !_dataset[index].active;
            setinformation(_dataset);

        } else {

            const _deleteRequest = await _call(`api/v1/information-page/${item.id}/`, 'DELETE');


            setinformation(information.filter(x => x != item));
        }

    }


    return (
        <div className="mytmg-container">
            {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}

            <div className="p-5">
                <Header />
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                    <img src={Icons.CONDITIONICON} style={{ width: 25, margin: 'auto', }} /></div>
                <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Information Section</h3>
                <p className="text-center">This is the admin panel information section</p>
                <div className="pr-4 analytics-container mt-3">
                    <div className="row m-0 onboarding-line">
                        <Button onClick={() => settype(0)} className={type == 0 ? "tab activated" : "tab"}>
                            <h3 className={type == 0 ? "primary-colored activated flexfull" : "primary-colored flexfull"}
                                style={{ fontSize: 16 }}
                            >Create Information</h3>
                        </Button>
                        <Button onClick={() => settype(1)} className={type == 1 ? "tab activated" : "tab"}>
                            <h3 className={type == 1 ? "primary-colored activated flexfull" : "primary-colored flexfull"}
                                style={{ fontSize: 16 }}
                            >View Information</h3>
                        </Button>
                    </div>
                    <div className="pt-1" />
                    <div className="row">
                        {type == 0 && <div className="col-md-12">
                            {contentarr.map(item => <div className="card p-4 mt-3">
                                <h6 >Heading</h6>
                                <InputGroup className="input">
                                    <Form.Control placeholder="Write down your content heading" value={item.heading} onChange={e => handleChange(item, 'heading', e.target.value)} className="input" style={{ height: 50 }} />
                                </InputGroup>
                                <div className="pt-4" />
                                <h6 >Description</h6>
                                <textarea className="form-control"
                                    onChange={e => handleChange(item, 'description', e.target.value)}
                                    style={{ height: 200 }}
                                ></textarea>
                                <ImageUploader
                                    sendFiles={e => handleChange(item, 'file', e[0])}
                                    numberOfFiles={1}
                                />
                                <Button
                                    onClick={() => handleSubmit(item)}
                                    className="header-btn w-100 mt-2">
                                    <h4>Submit</h4>
                                </Button>
                            </div>)}
                        </div>}

                        {type == 1 && <div className="col-md-12">
                            <div className="card p-5 w-100">
                                <h3 style={{ fontSize: 22 }}
                                >List of Informations</h3>
                                <h3 style={{ fontSize: 14, color: '#afafaf' }}
                                >List of all informations</h3>

                                <table className="mt-3">
                                    <tr style={{ background: '#1e1e1e' }}>
                                        <th style={{ padding: 15, color: '#fff' }}>Title</th>
                                        <th style={{ color: '#fff' }}>Description</th>
                                        <th style={{ color: '#fff' }}>Status</th>
                                        <th style={{ color: '#fff' }}>Delete</th>
                                    </tr>
                                    {information.map((item, index) => {
                                        return <>
                                            <tr style={{ background: index % 2 == 0 ? "#f7f7f7" : '#ffff' }}>
                                                <td style={{ padding: 15, width: 120 }}>{item.title}</td>
                                                <td style={{ width: '60%' }}>{item.description}</td>
                                                <td onClick={() => handleOperation('status', item)} style={{ width: 120, color: item.active ? 'green' : '#000', cursor: 'pointer' }}>{item?.active ? 'Active' : 'In-Active'}</td>
                                                <td onClick={() => window.confirm("Are you sure! You want to delete") ? handleOperation('delete', item) : console.log()} style={{ width: 100, cursor: 'pointer' }}>Delete</td>
                                            </tr>
                                        </>
                                    })}
                                </table>
                            </div>
                        </div>}
                    </div>
                </div>


                {/* <Button
                    onClick={() => {
                        if (type == 0) {
                            handleAdd()
                        } else {
                            handleAddOnboarding();
                        }
                    }}
                    className="header-btn abs-btn">
                    <img src={Icons.BOXPLUSICON} />
                    <h4 className="medium">{type == 1 ? 'Add New Content Library' : 'Add Information'}</h4>
                </Button> */}
            </div>
        </div>
    );
}

const ContentLibraryScreen = HOC(ContentLibrary, 5);

export default ContentLibraryScreen;




