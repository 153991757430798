import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { _call } from '../../utils/_api';
import { SCOREOPTIONS } from '../../utils/_dataset';

const ConditionQNA = (props) => {
    const { state, onClose, conditions, data, answers, setanswers, onResultClose } = props;
    const [loader, setloader] = useState(false);
    const [condition, setcondition] = useState();
    const [score, setscore] = useState(0);

    const onSave = async () => {
        console.log(condition, score)

        try {
            setloader(true)
            const _dataset = {
                condition_score: [...data?.condition_score, {
                    condition: parseInt(condition), score: parseInt(score)
                }],
            }

            const _updateanswer = await _call(`api/v1/answer/${data.id}/`, 'PATCH', _dataset);

            setloader(false)

            var __answers = [...answers]
            var __Index = answers?.findIndex(x => x.id == data.id);
            __answers[__Index] = _updateanswer?.data;
            setanswers(__answers)
            onResultClose(1)
            // setloader(false);
            // setresponseobj({
            //     mode: 1,
            //     message: 'Successfully updated',
            //     state: true
            // })

        } catch (err) {
            setloader(false)
            console.log(err);
            onResultClose(0)
            // setresponseobj({
            //     mode: 0,
            //     message: 'Some problem occured',
            //     state: true
            // })
        }
    }

    return (
        <Dialog
            open={state}

            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='p-4 '>
                <div style={{ pointerEvents: loader ? 'none' : 'auto', width: 500 }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull primary-colored'>Add Condition</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} style={{ width: 15, height: 15 }} />
                    </div>

                    <h4 className='mt-2 text-[10px]'>Condition</h4>
                    <select value={condition} onChange={e => setcondition(e.target.value)}
                        style={{ background: '#f7f7f7', width: '100%', height: 50, borderRadius: 4, paddingLeft: 10, marginTop: 10 }}
                    >
                        {conditions?.map(item => <option value={item?.id}>{item?.name}</option>)}
                    </select>
                    <h4 className='mt-4 text-[10px]'>Score</h4>
                    {/* <input className='input' style={{ background: '#f7f7f7', width: '100%', height: 50, borderRadius: 4, marginTop: 10, paddingLeft: 10 }} placeholder='Score' value={score} onChange={e => setscore(e.target.value)} /> */}
                    <select value={score} onChange={e => setscore(e.target.value)} className='q-input w-full focus:outline-none border-l mt-2' placeholder="Score" style={{ width: '100%' }} >
                        {SCOREOPTIONS.map(item => <option value={item}>{item}</option>)}
                    </select>
                    <div className="mt-4" />
                    <input className='w-full' />
                    <Button
                        onClick={onSave}
                        className="checkedbox"
                        style={{ height: 60, width: '100%', fontSize: 14, }}
                    >{loader ? <CircularProgress size={16} /> : "Save"}</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ConditionQNA;