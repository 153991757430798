import axios from 'axios';
import _, { isEmpty } from 'lodash';
import { URL } from './_constant';

axios.defaults.baseURL = URL;

const getToken = async () => {
    return await localStorage.getItem('token');
}

const _call = (url, method, data = {}, headers = null) => new Promise(async (resolve, reject) => {

    const token = await getToken();
    var headerObj = {
        Authorization: 'Token ' + token,
    }
    if (headers) {
        headerObj = headers;
    }

    var params = {
        method,
        url,
        headers: { ...headerObj, "x-forwarded-proto": "https" },
    };

    if (!isEmpty(data)) {
        params.data = data;
    }

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});

const _multipartCall = (url, method, data) => new Promise(async (resolve, reject) => {

    const token = await getToken();

    const fd = new FormData();

    data.map((item, index) => {
        fd.append(item.key, item.value);
    });

    var params = {
        method,
        url,
        headers: {
            Authorization: 'Token ' + token,
            "Content-Type": 'multipart/form-data',
            "x-forwarded-proto": "https"
        },
        data: fd
    }

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});

export { _call, _multipartCall };