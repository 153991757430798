import { Icons } from "../assets";
import { THEME } from "./_constant";

const SIDEBAROPTIONS = [
    { id: 0, name: 'Homepage', image: Icons.HOMEPAGEICON, route: 'home-page' },
    { id: 1, name: 'Questionaire', image: Icons.QUESTIONICON, route: 'question' },
    { id: 2, name: 'Clients', image: Icons.CLIENTICON, route: 'clients' },
    { id: 3, name: 'Specialists', image: Icons.SPECIALISTICON, route: 'onboarding-information' },
    { id: 4, name: 'Conditions', image: Icons.CONDITIONICON, route: 'condition' },
    { id: 5, name: 'Information Content', image: Icons.ANALYTICSICON, route: 'content' },
    // { id: 6, name: 'Symptoms', image: Icons.SYMPTOMSICON, route: 'symptoms' },
    { id: 7, name: 'Analytics', image: Icons.CLIENTANALYTICS, route: 'client-analytics' },
    { id: 8, name: 'Content Info', image: Icons.CONTENTINFO, route: 'content-section' },
    { id: 9, name: 'Diagnosis', image: Icons.CONTENTINFO, route: 'diagnosis' },
    { id: 10, name: 'Log Out', image: Icons.ANALYTICSICON, },
];

const HOMEOPTIONS = [
    { id: 0, name: 'Questionnaire Module', image: Icons.QUESTIONACTIVEICON, description: 'List of Questionnaire', route: 'question' },
    { id: 1, name: 'Clients Module', image: Icons.CLIENTACTIVEICON, description: 'List of Clients', route: 'clients' },
    { id: 2, name: 'Specialists Module', image: Icons.SPECIALISTACTIVEICON, description: 'List of Specialists', route: 'onboarding-information' },
    { id: 3, name: 'Conditions', image: Icons.CONDITIONACTIVEICON, description: 'List of Conditions', route: 'condition' },
    { id: 6, name: 'Analytics', image: Icons.CLIENTACTIVEICON, description: 'Client and Application Analytics', route: 'client-analytics' },
    { id: 7, name: 'Membership', image: Icons.BOXPLUSICON, description: 'Find the Membership Information', route: 'membership' },
];

const LINKSOPTIONS = ['CREATEDIAGNOSIS', 'PREVIOUSDIAGNOSIS', 'PROFILE', 'SPECIALIST', 'TERMS', 'PRIVACY', 'LIBRARY'];

const SYMPTOMSDATA = {
    head: ['Symptomps Area', 'Symptoms Name', 'Condition Linked'],
    body: [
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 1, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 2, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 3, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 4, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 5, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 6, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 7, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 8, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },
        { id: 9, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' } },

    ]
}

const QUESTIONDATA = {
    head: ['Question', 'Sorting Number', 'Mandatory'],
    body: [
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Allowed', color: THEME.green } },
        { id: 1, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Allowed', color: THEME.green } },
        { id: 2, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },
        { id: 3, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Allowed', color: THEME.green } },
        { id: 4, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },
        { id: 5, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Allowed', color: THEME.green } },
        { id: 6, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },
        { id: 7, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },
        { id: 8, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },
        { id: 9, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Not Allowed', color: THEME.red } },

    ]
}

const CLIENTDATA = {
    head: ['Index', 'Full Name', 'Date of Birth', 'Email'],
    body: [
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 1, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 2, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 3, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 4, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 5, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 6, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
    ]
}


const CONTENTDATA = {
    head: ['Title', 'Description', 'Active'],
    body: [
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 1, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 2, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 3, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 4, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 5, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
        { id: 6, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'INV-001-123-5678-908' }, tb3: { name: 'Headaches' }, tb4: { name: 'March 10, 2022' } },
    ]
}

const ONBOARDINGDATA = {
    head: ['Full Name', 'Area of strength', 'License number', 'Status'],
    body: [
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'Mouth' }, tb3: { name: '001-123-5678-908' }, tb4: { name: 'Allowed', color: THEME.green } },
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'Mouth' }, tb3: { name: '001-123-5678-908' }, tb4: { name: 'Not Allowed', color: THEME.red } },
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'Mouth' }, tb3: { name: '001-123-5678-908' }, tb4: { name: 'Allowed', color: THEME.green } },
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'Mouth' }, tb3: { name: '001-123-5678-908' }, tb4: { name: 'Not Allowed', color: THEME.red } },
        { id: 0, tb1: { name: 'William Shaqs', image: require('../assets/images/user1.png').default }, tb2: { name: 'Mouth' }, tb3: { name: '001-123-5678-908' }, tb4: { name: 'Allowed', color: THEME.green } },
    ]
}


const CONDITIONDATA = {
    head: ['Index', 'Condition', 'Pain Category'],
    body: [
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, tb4: { name: 'Lorem ipsum' } },

    ]
}


const DIAGNOSISDATA = {
    head: ['Condition Detail', 'Picture / Diagnosis Count', 'Name'],
    body: [
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, },

    ]
}

const CONTENTSECTIONDATA = {
    head: ['Sub Category', 'Condition', 'Created On'],
    body: [
        { id: 0, tb1: { name: 'Area name' }, tb2: { name: 'Symptoms name' }, tb3: { name: 'Link name' }, },

    ]
}

const USERCOLUMNS = ['User Type', "Count"];
const CONDITIONSCOLUMN = ['CONDITION NAME', 'COUNT'];
const CATEGORYCOLUMN = ['PAIN CATEGORY', 'COUNT'];

const SCOREOPTIONS = [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export {
    SIDEBAROPTIONS, HOMEOPTIONS, SYMPTOMSDATA, QUESTIONDATA, CLIENTDATA, ONBOARDINGDATA,
    CONDITIONDATA, CONTENTDATA, LINKSOPTIONS, DIAGNOSISDATA, CONTENTSECTIONDATA, USERCOLUMNS,
    CONDITIONSCOLUMN,
    CATEGORYCOLUMN, SCOREOPTIONS
}