import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header, HOC, HomeItem } from "../components";
import { HOMEOPTIONS } from "../utils/_dataset";
import { Icons } from "../assets";

const HomePageScreen = (props) => {

    const navigate = useNavigate();

    const handleClick = (item) => {
        if (item.route) {
            navigate('/' + item.route)
        }
    }

    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pr-4">
                    <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                        <img src={Icons.HOMEPAGEICON} style={{ width: 25, margin: 'auto', }} /></div>
                    <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Home Screen</h3>
                    <p className="text-center">This is the admin panel homepage</p>
                    <div className="pt-3" />
                    <div className="row" style={{ borderTop: '1px solid #D3D2D2' }}>
                        {HOMEOPTIONS.map((item, index) => <div onClick={() => handleClick(item)}
                            className="col-md-4 mt-2" key={index}>
                            <HomeItem data={item} />
                        </div>)}
                    </div>

                </div>
            </div>
        </div >
    );
}

const HomePage = HOC(HomePageScreen, 0);

export default HomePage;