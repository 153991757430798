import React, {useState, useEffect} from 'react';
import {_call} from './_api'


const useSingleDataHook = (request) => {
    const { url, data = {}, method, result = false } = request;
    const [isLoading, setisLoading] = useState(false);
    const [response, setresponse] = useState([]);
    const [error, seterror] = useState([]);

    useEffect(() => {
        const handleData = async () => handleProcess();
        handleData();
    }, []);

    const handleProcess = async (embed = url) => {
        try {
            setisLoading(true);
            const _request = await _call(embed, method, data);
            if (result) {
                setresponse(_request.data.results);
            } else {
                setresponse(_request.data);
            }
            setisLoading(false);
        } catch (err) {
            setisLoading(false);
            seterror(err);
        }
    }

    const onRefetch = (_url) => {
        handleProcess(_url)
    }

    return [isLoading, response, error, onRefetch];
}

export {
    useSingleDataHook
}