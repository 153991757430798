import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import TabularItem from "../components/_tabularitem";
import { CircularProgress } from "@mui/material";
import { _call } from "../utils/_api";
import { DIAGNOSISDATA } from "../utils/_dataset";
import { THEME } from '../utils/_constant'
import { isEmpty } from 'lodash'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const Conditions = (props) => {

    const [loader, setloader] = useState(false);
    const [url, seturl] = useState('api/v1/diagnose/');
    const [next, setnext] = useState(true);
    const [dataset, setdataset] = useState([]);
    const [__dataset, set__dataset] = useState([]);
    const [selected, setselected] = useState([]);
    const [search, setsearch] = useState('');
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    });

    useEffect(() => {
        const handleData = async () => {
            try {
                setloader(true);
                const _getDiagnosis = await _call(url, 'GET');
                // setdataset(_getDiagnosis.data);
                seturl(_getDiagnosis.data.next);
                var _shape = [];
                var _dataset = _getDiagnosis.data.results.map(item => {
                    const obj = {
                        id: item.id,
                        tb1: {
                            name: item?.condition_details?.map(i => i.name)?.toString() || 'Not Found'
                        },
                        tb2: {
                            name: item?.diagnose_pictures_qna_details ? item?.diagnose_pictures_qna_details.length : item?.diagnose_qna_details.length.toString() || 'Not Found',
                        },
                        tb3: {
                            name: (item?.first_name + ' ' + item?.last_name).trim() || 'Not Found'
                        },
                        ...item
                    };
                    _shape.push(obj);
                });
                setloader(false);
                setnext(false);
                setdataset([...dataset, ..._shape]);
                set__dataset([...dataset, ..._shape])

            } catch (err) {
                console.log(err);
            }
        }
        if (next) {
            handleData()
        }
    }, [next]);

    useEffect(() => {
        if (search == '') {
            setdataset(__dataset);
        } else {
            setdataset(__dataset.filter(x => x?.tb3?.name?.includes(search)))
        }
    }, [search]);


    return (
        <div className="mytmg-container">

            <div className="p-5">
                {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}
                <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                    <img src={Icons.CONTENTINFO} style={{ width: 25, margin: 'auto', }} /></div>
                <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Diagnosis</h3>
                <p className="text-center">This is the admin panel diagnosis</p>
                {selected.length == 0 && <input className="text-center mt-3" style={{ margin: 'auto', display: "block", width: 400, height: 60 }} placeholder="Search Name" value={search} onChange={e => setsearch(e.target.value)} />}
                {/* <Header /> */}
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                        <h6 className='flexfull mt-2'>Please wait...</h6>
                    </div>
                }
                <div className="pt-3 pr-4">
                    <div className="row m-0 align-item-center">
                        {selected.length != 0 && <img src={Icons.BACKICON} className="mr-3" onClick={() => !isEmpty(selected) && setselected([])} />}
                    </div>
                    <div className="pt-3" />

                    {selected.length <= 0 ?
                        <div className="w-100">
                            <TabularItem
                                exceptional
                                onOpen={item => setselected(item)}
                                data={DIAGNOSISDATA} dataset={dataset} />
                            <Button className="float-right"
                                onClick={() => setnext(true)}
                            >Next</Button>
                        </div>
                        :
                        <>
                            <div className="w-100">

                                {selected?.diagnose_qna_details.length > 0 &&
                                    <div className="card p-4 mt-2 text-center" style={{ background: '#fff' }}>
                                        <h2 style={{ fontSize: 25, marginBottom: 10 }}>{selected?.tb1?.name}</h2>
                                        <br />
                                        <div className="p-3">
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                                    <TableRow>
                                                        <TableCell>Name: {selected?.first_name + ' ' + selected?.last_name}</TableCell>
                                                        <TableCell align="center">Weight: {selected?.weight}</TableCell>
                                                        <TableCell align="center">Height: {selected?.height}</TableCell>
                                                        <TableCell align="center">Date of Birth: {selected?.dob}</TableCell>
                                                        <TableCell align="center">Gender: {selected?.gender == 0 ? 'Male' : 'Female'}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        <h4>Questions / Answer</h4>
                                        <div className="row m-0 m-1">
                                            {selected?.diagnose_qna_details?.map((item, index) => {
                                                return (
                                                    <div className="col-6 mt-4" >
                                                        <div className="card p-3 shadow-md" style={{ minHeight: 50, }}>
                                                            <h5 style={{ fontSize: 14 }}>{index + 1}. {item?.question}</h5>
                                                            <h6 style={{ color: THEME.greyish, fontSize: 14, marginTop: 10 }}>{item?.answer}</h6>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <h4 className="mt-4">General Questions / Answer</h4>
                                        <div className="row m-0 m-1">
                                            {selected?.diagnose_general_qna_details?.map((item, index) => {
                                                return (
                                                    <div className="col-12 mt-4" >
                                                        <div className="card p-3 shadow-md" style={{ minHeight: 50, }}>
                                                            <h5 style={{ fontSize: 14 }}>{index + 1}. {item?.question}</h5>
                                                            <h6 style={{ color: THEME.greyish, fontSize: 14, marginTop: 10 }}>{item?.answer}</h6>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }



                                {selected?.diagnose_qna_details.length <= 0 &&
                                    <div className="card p-4 mt-4">
                                        <h4>Picture Based Diagnosis</h4>
                                        <h6 style={{ color: THEME.greyish }}>{selected?.condition_details?.name}</h6>

                                        <div className="row m-0 mt-3">
                                            {selected?.diagnose_general_qna_details?.map((item, index) => {
                                                return (
                                                    <div className="card p-3 col-3 shadow-sm" style={{ backgroundColor: '#f7f7f7' }}>
                                                        <h5 style={{ fontSize: '14px !important' }}>{index + 1}. {item?.question}</h5>
                                                        <h6 style={{ color: THEME.greyish }}>{item?.answer}</h6>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>

        </div >
    );
}

const DiagnosisScreen = HOC(Conditions, 9);

export default DiagnosisScreen;