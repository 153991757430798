import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import TabularItem from "../components/_tabularitem";
import { _call } from "../utils/_api";
import { CONDITIONDATA, QUESTIONDATA, SYMPTOMSDATA } from "../utils/_dataset";

const Conditions = (props) => {
    const [dialogstate, setdialogstate] = useState({
        state: false
    });
    const [painInfo, setpainInfo] = useState([]);
    const [selectedCondition, setselectedCondition] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [selected, setselected] = useState('');
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    })


    useEffect(() => {
        handleData();
        handleGetCondition();
    }, []);

    const handleData = async () => {

        try {
            const _data = await _call('api/v1/pain-category/', 'GET');
            setpainInfo(_data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGetCondition = async (pain = 1) => {

        try {
            const _data = await _call('api/v1/condition/?pain_category=' + pain, 'GET');
            const data = _data.data;
            var shape = [];
            const painName = painInfo.find(x => x.id == pain);
            data.map((item, index) => {
                const obj = {
                    id: item.id,
                    tb1: {
                        name: index + 1
                    },
                    tb2: {
                        name: item.name,
                    },
                    tb3: {
                        name: painName?.category_name || 'Neuropathic Pain'
                    },
                }
                shape.push(obj);
            });
            console.log(shape)
            setdataset(shape);
        } catch (err) {
            console.log(err);
        }
    }

    const handleSave = (type, data = {}) => {
        var datasetclone = [...dataset];
        if (type == 1) {
            datasetclone.unshift({
                id: data.id,
                tb1: {
                    name: "New Added"
                },
                tb2: {
                    name: data.name,
                },
                tb3: {
                    name: data.category
                },
            })
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully saved',
                mode: 1
            });


        } else {
            setresponseobj({
                state: true,
                message: 'Some problem occured',
                mode: 0
            })
        }
    }

    useEffect(() => {
        handleGetCondition(selected);
    }, [selected]);


    return (
        <div className="mytmg-container">

            <div className="p-5">
                {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}
                <div style={{ margin: 'auto', width: 50, height: 50, display: 'block', background: '#efefef', paddingTop: 14, borderRadius: 4 }}>
                    <img src={Icons.CONDITIONICON} style={{ width: 25, margin: 'auto', }} /></div>
                <h3 className="text-center mt-3" style={{ fontSize: 24, fontFamily: 'Manrope-Bold' }}>Condition</h3>
                <p className="text-center">This is the admin panel condition</p>
                <div className="pt-3" />
                <Header />
                <div className="pr-4">
                    <div className="row m-0">
                        {/* <h3 className="primary-colored flexfull">Condition</h3> */}
                        <select className="mr-2 selectinput" onChange={e => setselected(e.target.value)}>
                            <option>Select any optionc</option>
                            {painInfo.map(item => <option value={item.id}>{item.category_name}</option>)}
                        </select>
                        <Button
                            onClick={() => setdialogstate({
                                ...dialogstate,
                                state: true
                            })}
                            className="header-btn"
                            style={{ background: '#1e1e1e', border: 'none', width: 220 }}
                        >
                            <img src={Icons.WHITEPLUSICON} />
                            <h4>Create new condition</h4>
                        </Button>
                    </div>
                    <div className="pt-3" />

                    <div className="w-100">
                        <TabularItem onOpen={item => {
                            setselectedCondition(item);
                            setdialogstate({ edit: true, state: true });

                        }} data={CONDITIONDATA} dataset={dataset} />
                    </div>
                </div>
            </div>
            <Dialog.Condition dataset={selectedCondition} state={dialogstate.state} selected={selected} painInfo={painInfo} onSave={(type, data) => handleSave(type, data)} onClose={() => setdialogstate({ ...dialogstate, state: false })} />
        </div >
    );
}

const ConditionScreen = HOC(Conditions, 4);

export default ConditionScreen;