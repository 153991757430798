import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { _call } from '../../utils/_api';

const PictureModal = (props) => {
    const { state, onClose, onSave, painInfo, dataset, selected } = props;
    const [condition, setcondition] = useState('');
    const [tab2selected, settab2selected] = useState([]);
    const [loader, setloader] = useState(false);

    useEffect(() => {
        if (!isEmpty(dataset)) {
            setcondition(dataset.tb2.name)
            var selectedPainCategory = painInfo.find(x => x.category_name == dataset.tb3.name);
            settab2selected(selectedPainCategory)
        }
    }, [dataset]);

    const handleSet = (item) => {
        settab2selected(item);
    }

    const handleSaveCondition = async () => {
        setloader(true)
        try {
            const _dataset = {
                name: condition,
                pain_category: tab2selected.id
            }
            const _createcondition = await _call('api/v1/condition/', 'POST', _dataset);
            var result = {
                ..._createcondition.data,
                category: painInfo.find(x => x.id == tab2selected.id)?.category_name
            }
            onSave(1, result);
            setloader(false)
        } catch (err) {
            console.log(err);
            onSave(0);
            setloader(false)
        }

    }

    const handleUpdateCondition = async () => {
        setloader(true)
        try {
            const _dataset = {
                name: condition,
                pain_category: tab2selected.id
            }
            const _createcondition = await _call(`api/v1/condition/${dataset.id}/`, 'PUT', _dataset);
            console.log(_createcondition.data);
            onSave(1);
            setloader(false)
        } catch (err) {
            console.log(err);
            onSave(0);
            setloader(false)
        }
    }

    return (
        <Dialog
            open={state}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='mytmj-modal'>
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>Loading...</h5>
                    </div>
                }
                <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull'>Add Condition</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} className="cross-icon" />
                    </div>

                    <div className='body mt-4'>
                        <h6>Condition</h6>
                        <input className='input' value={condition} onChange={e => setcondition(e.target.value)} placeholder='Type condition' />
                        <div className='pt-3' />
                        <h6>Pain Categories</h6>
                        <div className='row mt-1 ml-0'>
                            {painInfo.map(item => <Button style={{ borderWidth: tab2selected != item ? 1 : 0, borderColor: THEME.green, fontSize: 10, margin: 3, backgroundColor: tab2selected == item ? THEME.green : '#fff', color: tab2selected == item ? '#fff' : THEME.green }}
                                variant="outline" onClick={() => handleSet(item)}>{item.category_name}</Button>)}
                        </div>
                        <div className='pt-2' />
                        <Button className="primary-btn mt-3" onClick={() => {
                            if (isEmpty(dataset)) {
                                handleSaveCondition()
                            } else {
                                handleUpdateCondition()
                            }
                        }}>
                            {isEmpty(dataset) ? 'Save' : 'Update'} Condition
                        </Button>
                    </div>

                </div>
            </div>
        </Dialog>
    );
}

export default PictureModal;