import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Header, HOC, HomeItem, ImageUploader } from "../components";
import { HOMEOPTIONS } from "../utils/_dataset";
import { _call, _multipartCall } from "../utils/_api";
import { Button, CircularProgress } from "@mui/material";
import _ from 'lodash';
import { Icons } from "../assets";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const Component = (props) => {
    const [loader, setloader] = useState(false);
    var editorRef = useRef();
    const [painInfo, setpainInfo] = useState([]);
    const [painoptions, setpainoptions] = useState([]);
    const [mode, setmode] = useState(0);
    const [selectedPC, setselectedPC] = useState(-1);
    const [situations, setsituations] = useState([]);
    const [currentIndex, setcurrentIndex] = useState([0]);
    const [prData, setprData] = useState([]);
    const [srData, setsrData] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [situationText, setsituationText] = useState('');
    const [imagesData, setimagesData] = useState([]);
    const [pictureInfo, setpictureInfo] = useState({
        image: [],
        sorting_number: 0,
        notes: '',
        situation: 0,
        condition: -1,
    });
    const [conditionsData, setconditionsData] = useState([]);
    const navigate = useNavigate();

    const [childrensituations, setchildrensituations] = useState([]);

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            setloader(true);
            const _data = await _call('api/v1/pain-category/', 'GET');
            setpainoptions(_data.data.map(item => {
                item.label = item.category_name;
                item.value = item.category_name;
                item.id = item.id;
                return item;
            }));
            setmode(0)
            setpainInfo(_data.data);
            setsrData([]);
            setdataset([]);
            setprData([]);
            setimagesData([]);
            setsituationText('');
            setsituations([]);
            setselectedPC('');
            setloader(false);
        } catch (err) {
            console.log(err);
            setloader(false);
        }
    }

    useEffect(() => {
        if (selectedPC != -1) {
            handleGetSituations();

        }
    }, [selectedPC]);


    const handleGetSituations = async () => {

        try {

            const _getSituations = await _call(`api/v1/situation/?pain_category=${selectedPC}`, 'GET');
            const _data = await _call('api/v1/condition/?pain_category=' + selectedPC, 'GET');
            setconditionsData(_data.data);
            setsituations(_getSituations.data);

        } catch (err) {
            console.log(err);
        }

    }


    const handleGetChildrenSituations = async (item) => {
        setmode(0);

        setprData(item);
        try {
            if (!item.parent_situation && !item.has_child) {
                const _images = await handleCheckImages(item);
                if (_images) {
                    setpictureInfo({
                        ...pictureInfo,
                        situation: item.id
                    })
                }
                setdataset([]);
                // setsrData([]);
                return;
            }

            if (item.has_child) {
                const getChildrens = await _call(`api/v1/situation/?pain_category=${selectedPC}&parent_situation=${item.id}`, 'GET');
                setsrData([]);
                setimagesData([]);
                setdataset(getChildrens.data);
            } else {
                const _images = await handleCheckImages(item);
                if (_images) {
                    setmode(2);

                } else {
                    setimagesData([]);
                    setsrData(item);
                }
                setpictureInfo({
                    ...pictureInfo,
                    situation: item.id
                })

            }

        } catch (err) {
            console.log(err);
        }
    }

    const handleCheckImages = async (item) => new Promise(async (resolve, reject) => {

        try {

            const _getImages = await _call(`api/v1/picture-question/?pain_category=${selectedPC}&situation=${item.id}`);
            console.log(_getImages.data);
            setimagesData(_getImages.data);
            resolve(!_.isEmpty(_getImages.data));
        } catch (err) {
            console.log(err);
        }

    });

    const _renderChildrens = () => {
        if (currentIndex.length == 0) return <></>

        // currentIndex.map(i => childrensituations[i].map(item => <Button
        //     onClick={() => handleGetChildrenSituations(item, i)}
        //     className={"checkedbox excep"}
        //     style={{ height: 60, width: '200px', fontSize: 14, marginLeft: 15 }}
        // >{item.name}</Button>))
    }

    const handleCreateSituation = async () => {

        var _dataset = {
            name: situationText,
            pain_category: selectedPC,
            parent_situation: _.isEmpty(dataset) ? _.isEmpty(prData) ? null : prData.id : !_.isEmpty(srData) ? srData.id : dataset[0].parent_situation
        };

        try {
            setloader(true);
            const _createSituation = await _call('api/v1/situation/', 'POST', _dataset);
            console.log(_createSituation)
            handleData();
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }

    }

    const handleCreatePicture = async () => {
        const { sorting_number = 0, image, situation, condition } = pictureInfo;


        if (_.isEmpty(image)) {
            alert('Please upload the picture');
            return;
        }

        var _dataset = [
            { key: 'pain_category', value: selectedPC },
            { key: 'sorting_number', value: sorting_number },
            { key: 'notes', value: editorRef?.current?.getContent() },
            { key: 'situation', value: situation },
            { key: 'condition', value: condition },
            {
                key: 'image', value: image
            },
        ]


        try {
            setloader(true)
            const _createImage = await _multipartCall('api/v1/picture-question/', 'POST', _dataset);
            // console.log(_createImage.data, [...imagesData, _createImage.data]);
            var imagesDataClone = [...imagesData];
            imagesDataClone.push(_createImage.data);
            setimagesData(imagesDataClone);
            setpictureInfo({
                ...pictureInfo,
                notes: '',
                sorting_number: 0,
                image: []
            })
            setmode(2);
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }
        console.log(_dataset);

    }

    const handleDelete = async (item) => {
        try {
            setloader(true);
            const _deleteImage = await _call(`api/v1/picture-question/${item.id}/`, 'DELETE');
            console.log(_deleteImage.data);
            var ic = [...imagesData];
            ic = ic.filter(x => x != item);
            setimagesData(ic);
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }
    }


    return (
        <div className="mytmg-container">
            <div className="p-5">
                <Header />
                <div className="pt-5 pr-4 analytics-container">
                    <div className="row m-0 align-item-center">
                        <img src={Icons.BACKICON} className="mr-3" onClick={() => _.isEmpty(imagesData) ? navigate('/question') : handleData()} />
                        <h3 className="mt-2">Picture Based Questions</h3>
                    </div>
                    <div className="pt-3" />
                    {loader &&
                        <div className='text-center'>
                            <CircularProgress color="inherit" />
                            <h5 className='flexfull mt-2'>We are submitting your request.</h5>
                            <h6 className='flexfull mt-2'>Please wait...</h6>
                        </div>
                    }
                    <div className="row">
                        <div className={(mode == 0) ? "col-md-12" : "col-md-8"}>
                            {(mode == 0 || mode == 1) &&
                                <div className="card p-4">
                                    <h6 >Select Pain Category</h6>
                                    <div className="pt-1" />
                                    <select className="q-input"
                                        onChange={e => setselectedPC(e.target.value)}>
                                        {painoptions.map(item => <option value={item.id}>{item.label}</option>)}
                                    </select>
                                    {!_.isEmpty(selectedPC) && !_.isEmpty(situations) &&
                                        <>
                                            <div className="pt-4" />
                                            <h6>Parent Situation</h6>

                                            <div className="row">
                                                {situations.map(item => <Button
                                                    onClick={() => {
                                                        handleGetChildrenSituations(item);
                                                    }}
                                                    className={parseInt(prData.id) == parseInt(item.id) ? "checkedbox mt-2" : "checkedbox excep mt-2"}
                                                    style={{ height: 60, width: '200px', fontSize: 14, marginLeft: 15, color: parseInt(item.id) != parseInt(prData.id) ? '#252525' : '#fff' }}
                                                >{item.name}</Button>)}
                                            </div>
                                            {!_.isEmpty(dataset) &&

                                                <>
                                                    <div className="pt-3" />
                                                    <h6>Children Situation</h6>
                                                    <div className="row mt-1">
                                                        {dataset.map(item => <Button
                                                            onClick={() => {
                                                                handleGetChildrenSituations(item);
                                                            }}
                                                            className={parseInt(srData.id) == parseInt(item.id) ? "checkedbox" : "checkedbox excep"}

                                                            style={{ height: 60, width: '200px', fontSize: 14, marginLeft: 15, color: parseInt(srData.id) == parseInt(item.id) ? '#fff' : '#252525' }}
                                                        >{item.name}</Button>)}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                    <div className="pt-4" />
                                    <h6>Name of Situation</h6>
                                    <input className='q-input' value={situationText} onChange={e => setsituationText(e.target.value)} placeholder='Type name of situation here' />
                                    <div className="pt-4" />
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            {_.isEmpty(imagesData) &&
                                                <Button
                                                    onClick={() => handleCreateSituation()}
                                                    className={"checkedbox excep"}
                                                    style={{ height: 60, minWidth: '200px', fontSize: 14, }}
                                                >Create Situation</Button>
                                            }
                                            <Button
                                                onClick={() => setmode(1)}
                                                className={"checkedbox ml-4"}
                                                style={{ height: 60, minWidth: '200px', fontSize: 14, color: '#fff' }}
                                            >Add Images</Button>
                                        </div>
                                    </div>
                                </div>}
                            {mode == 2 &&
                                <>
                                    <div className="row">
                                        {imagesData.map(item => {
                                            return (
                                                <div className="col-md-6">
                                                    <div className="card p-4">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <img src={item?.image?.split('?')[0]} className="sm-img" />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h6 className="mb-1" style={{ fontSize: 14 }}>Pain Category: {painInfo.find(x => item.pain_category == x.id)?.category_name}</h6>
                                                                <h6 className="mb-1" style={{ fontSize: 14 }}>Condition: {conditionsData?.find(x => item?.condition == x.id)?.name}</h6>
                                                                <p className="mt-0 mb-1">Sorting Number: {item.sorting_number}</p>
                                                                {/* <p className="mb-1" style={{ fontSize: 12 }}>{(renderHTML(item.notes.toString()))}</p> */}
                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">
                                                                        {/* <Button
                                                                            className={"checkedbox excep mt-3"}
                                                                            style={{ height: 40, minWidth: 100, fontSize: 12, color: '#fff', borderRadius: 0, marginRight: 20 }}
                                                                        >Edit</Button> */}
                                                                        <Button
                                                                            onClick={() => handleDelete(item)}
                                                                            className={"checkedbox mt-3"}
                                                                            style={{ height: 40, minWidth: 100, fontSize: 12, color: '#fff', borderRadius: 0 }}
                                                                        >Delete</Button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </>
                            }
                        </div>
                        {(mode == 1 || mode == 2) &&
                            <div className="col-md-4">
                                <div className="card p-4">
                                    <h6 >Select Image</h6>
                                    <div style={{ marginTop: -14 }} />
                                    <ImageUploader
                                        sendFiles={e => setpictureInfo({
                                            ...pictureInfo,
                                            image: e[0]
                                        })}
                                        numberOfFiles={1}
                                    />
                                    <h6 className="mt-3">Conditions</h6>
                                    <select className="q-input" value={pictureInfo?.condition} onChange={e => setpictureInfo({ ...pictureInfo, condition: e.target.value })}>
                                        <option>Select Condition</option>
                                        {conditionsData.map(item => {
                                            return (
                                                <option value={item.id}>{item.name}</option>
                                            );
                                        })}
                                    </select>
                                    <h6 className="mt-3">Sorting Number</h6>
                                    <input className='q-input' placeholder='Sorting Number' value={pictureInfo?.sorting_number} onChange={e => setpictureInfo({ ...pictureInfo, sorting_number: e.target.value })} />
                                    <h6 className="mt-3">Notes</h6>
                                    {/* <textarea className="q-input" style={{ minHeight: 100 }}
                                        value={pictureInfo?.notes}
                                        onChange={e => setpictureInfo({ ...pictureInfo, notes: e.target.value })}
                                    ></textarea> */}
                                    <Editor
                                        onInit={(evt, editor) => {
                                            editorRef.current = editor
                                        }}
                                        apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                                        initialValue=""
                                        init={{
                                            height: 400,
                                            menubar: true,
                                            image_title: true,
                                            file_picker_types: 'file image media',
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview anchor",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime media table paste image code help wordcount",
                                            ],
                                            toolbar:
                                                "undo redo | formatselect | " +
                                                "bold italic backcolor | alignleft aligncenter " +
                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                "removeformat | help",
                                            content_style:
                                                `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                                        }}
                                    />
                                    <Button
                                        onClick={() => handleCreatePicture()}
                                        className={"checkedbox mt-4"}
                                        style={{ height: 60, minWidth: '200px', fontSize: 14, color: '#fff' }}
                                    >Upload Image</Button>
                                </div>


                            </div>
                        }
                    </div>
                    {/* <div className="row mt-4">
                        <div className="col-md-3">
                            <div className="card p-4">
                                <h6 className='flexfull mt-2' style={{ fontSize: 16 }}>I have concular</h6>
                                <img src={require('../assets/icons/arrow-right.png')} style={{ width: 30, position: 'absolute', right: 10, top: 25 }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4">
                                <h6 className='flexfull mt-2' style={{ fontSize: 16 }}>I have concular</h6>
                                <img src={require('../assets/icons/arrow-right.png')} style={{ width: 30, position: 'absolute', right: 10, top: 25 }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4">
                                <h6 className='flexfull mt-2' style={{ fontSize: 16 }}>I have concular</h6>
                                <img src={require('../assets/icons/arrow-right.png')} style={{ width: 30, position: 'absolute', right: 10, top: 25 }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4">
                                <h6 className='flexfull mt-2' style={{ fontSize: 16 }}>I have concular</h6>
                                <img src={require('../assets/icons/arrow-right.png')} style={{ width: 30, position: 'absolute', right: 10, top: 25 }} />
                            </div>
                        </div>

                    </div> */}
                </div>
            </div>
        </div>
    );
}

const CreatePictureQNA = HOC(Component, 1);

export default CreatePictureQNA;