const iconLink = './icons/';

const LOGO = require(iconLink + 'logo.png');
const EMAILICON = require(iconLink + 'email.png');
const PASSWORDICON = require(iconLink + 'password.png');
const HOMEPAGEICON = require(iconLink + 'homepage.png');
const QUESTIONICON = require(iconLink + 'question.png');
const CLIENTICON = require(iconLink + 'client.png');
const SPECIALISTICON = require(iconLink + 'specialist.png');
const CONDITIONICON = require(iconLink + 'condition.png');
const ANALYTICSICON = require(iconLink + 'analytics.png');
const SYMPTOMSICON = require(iconLink + 'symptoms.png');
const CLIENTANALYTICS = require(iconLink + 'clientanalytics.png');
const CONTENTINFO = require(iconLink + 'contentinfo.png');
const SEARCHICON = require(iconLink + 'search.png');
const NOTIFICATIONICON = require(iconLink + 'notification.png');
const SETTINGICON = require(iconLink + 'setting.png');
const BOXPLUSICON = require(iconLink + 'box-plus.png');
const QUESTIONACTIVEICON = require(iconLink + 'questionactive.png');
const CLIENTACTIVEICON = require(iconLink + 'clientactive.png');
const SPECIALISTACTIVEICON = require(iconLink + 'specialistactive.png');
const CONDITIONACTIVEICON = require(iconLink + 'conditionactive.png');
const ANALYTICSACTIVEICON = require(iconLink + 'analyticsactive.png');
const SYMPTOMSACTIVEICON = require(iconLink + 'symptomsactive.png');
const CLIENTANALYTICSICON = require(iconLink + 'clientanalytics.png');
const WHITEPLUSICON = require(iconLink + 'whiteplus.png');
const CROSSICON = require(iconLink + 'cross.png');
const COLOREDPLUSICON = require(iconLink + 'coloredplus.png');
const TICKICON = require(iconLink + 'tick.png');
const BACKICON = require(iconLink + 'back.png');


const Icons = {
    LOGO,
    EMAILICON,
    PASSWORDICON,
    HOMEPAGEICON,
    QUESTIONICON,
    CLIENTICON,
    SPECIALISTICON,
    CONDITIONICON,
    ANALYTICSICON,
    SYMPTOMSICON,
    CLIENTANALYTICS,
    CONTENTINFO,
    SEARCHICON,
    NOTIFICATIONICON,
    SETTINGICON,
    BOXPLUSICON,
    QUESTIONACTIVEICON,
    CLIENTACTIVEICON,
    SPECIALISTACTIVEICON,
    CONDITIONACTIVEICON,
    ANALYTICSACTIVEICON,
    SYMPTOMSACTIVEICON,
    CLIENTANALYTICSICON,
    WHITEPLUSICON,
    CROSSICON,
    COLOREDPLUSICON,
    TICKICON,
    BACKICON
}

export {
    Icons
}