import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Icons } from '../../assets';
import Slide from '@mui/material/Slide';
import _, { isEmpty } from 'lodash';
import { TransitionProps } from '@mui/material/transitions';
import { CircularProgress, Input } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { THEME } from '../../utils/_constant';
import { _call } from '../../utils/_api';

const ContentCategory = (props) => {
    const { state, onClose, onSave} = props;
    const [loader, setloader] = useState(false);
    const [categoryInfo, setcategoryInfo] = useState('');

    const handleSave = async() => {
        
        try{
            setloader(true)
            const _dataset = {
                category_name: categoryInfo
            };
            const _createCategory = await _call('api/v1/main-category/','POST',_dataset);
            setcategoryInfo('');
            onSave(_createCategory.data);
            setloader(false)
        }catch(err){
            console.log(err);
            setloader(false)
        }

    }

    return (
        <Dialog
            open={state}
            
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='p-4'>
                {loader &&
                    <div className='text-center'>
                        <CircularProgress color="inherit" />
                        <h5 className='flexfull mt-2'>Loading...</h5>
                    </div>
                }
                <div style={{ pointerEvents: loader ? 'none' : 'auto' }}>
                    <div className='header row m-0'>
                        <h3 className='flexfull primary-colored'>Create Category</h3>
                        <img onClick={onClose} src={Icons.CROSSICON} style={{width:20,height:20}} />
                    </div>
                    <p style={{color:'#afafaf'}}>
                        You can create category which will be used to create content
                        </p>
                        <div className="mt-5 pt-2" />
                        <h4 style={{fontSize:16}}>Category Name</h4>
                        <input className='q-input w-100' 
                        placeholder='Category Name'
                        value={categoryInfo}
                        onChange={e => setcategoryInfo(e.target.value)}
                        style={{ borderLeft: '1px solid #fff', borderRight: '1px solid #fff' }} />
                        
                    <div className="mt-4"/>
                    <Button
                        onClick={handleSave}
                        className="checkedbox"
                        style={{ height: 60, width: '100%', fontSize: 14, }}
                    >Save</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ContentCategory;