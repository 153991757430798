import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Icons } from "../assets";
import _ from 'lodash';
import { _call } from "../utils/_api";
import { Response } from "../components";
import { useNavigate } from "react-router-dom";

const LoginScreen = (props) => {
    const [usercredentials, setusercredentials] = useState({
        email: '',
        password: ''
    });
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    })
    const navigate = useNavigate();

    const handleLogin = async () => {

        const { email, password } = usercredentials;

        if (email == '' || password == '') {
            setresponseobj({
                state: true,
                message: 'Please fill all details',
                mode: 0
            })
            return;
        }

        try {

            const _dataset = {
                username: email,
                password: password,
            }

            const _loginUser = await _call('api/v1/login/', 'POST', _dataset, {
                'Content-Type': 'application/json',
                'admin-protected': 1
            });
            console.log(_loginUser.data);
            const data = _loginUser.data;
            localStorage.setItem('token', _loginUser.data.token);
            localStorage.setItem('usermode', _.isEmpty(data.user?.specialistprofile) ? "0" : "1");
            navigate('/home-page');
        } catch (err) {
            console.log(err.response);
            setresponseobj({
                state: true,
                message: 'Invalid Credentials!',
                mode: 0
            })
        }

    }


    return (
        <div className="login-container">

            <div className="col-md-3">
                <div className="card">

                    <img src={Icons.LOGO} className="logo" />
                    {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}
                    <div className="pt-3" />
                    <h1 className="heading">Log In</h1>
                    <div className="w-100 pl-5 pr-5 pb-3 pt-3">
                        <InputGroup className="input-container">
                            <InputGroup.Text className="transparent-icon">
                                <img src={Icons.EMAILICON} />
                            </InputGroup.Text>
                            <Form.Control value={usercredentials.email} onChange={e => setusercredentials({ ...usercredentials, email: e.target.value })} placeholder="Email Address" className="input" />
                        </InputGroup>
                        <InputGroup className="mt-3 input-container">
                            <InputGroup.Text className="transparent-icon">
                                <img src={Icons.PASSWORDICON} />
                            </InputGroup.Text>
                            <Form.Control placeholder="Password" type="password" value={usercredentials.password} onChange={e => setusercredentials({ ...usercredentials, password: e.target.value })} className="input" />
                        </InputGroup>
                        <Button
                            onClick={() => handleLogin()}
                            className="btn mt-3" >
                            Log In
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginScreen;