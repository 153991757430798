import React, { useState, useEffect, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { Icons } from "../assets";
import { Dialog, Header, HOC, HomeItem, Response } from "../components";
import TabularItem from "../components/_tabularitem";
import { _call } from "../utils/_api";
import { THEME } from "../utils/_constant";
import _ from 'lodash'
import { CONDITIONDATA, CONTENTDATA, QUESTIONDATA, SYMPTOMSDATA } from "../utils/_dataset";

const ContentInfo = (props) => {
    const [dialogstate, setdialogstate] = useState({
        state: false
    });
    const [selectedContent, setselectedContent] = useState([]);
    const [selectedtype, setselectedtype] = useState(0);
    const [dataset, setdataset] = useState([]);
    const [responseobj, setresponseobj] = useState({
        state: false,
        message: ''
    })



    const handleGetOnboardingInfo = async (type = 0) => {

        try {
            const _data = await _call(type == 0 ? 'api/v1/information-page/' : 'api/v1/education/', 'GET');
            const data = _data.data.results;
            var shape = [];
            console.log(data)

            // data?.map((item, index) => {
            //     const obj = {
            //         id: item.id,
            //         tb1: {
            //             name: item.title
            //         },
            //         tb2: {
            //             name: item.description,
            //         },
            //         tb3: {
            //             name: item.active ? 'True' : 'False',
            //             color: item.active ? THEME.green : THEME.greyish
            //         },
            //     }
            //     shape.push(obj);
            // })
            setdataset(shape);
        } catch (err) {
            console.log(err);
        }
    }



    const handleSave = (type, data = {}) => {
        var datasetclone = [...dataset];
        if (type == 1) {

            datasetclone.unshift({
                id: data.id,
                tb1: {
                    name: data.title
                },
                tb2: {
                    name: data.description,
                },
                tb3: {
                    name: 'True',
                    color: THEME.green
                },
            });

            setdataset(datasetclone)
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully saved',
                mode: 1
            });


        } else if (type == 2) {
            datasetclone = datasetclone.filter(x => x != data);
            setdataset(datasetclone);
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully removed',
                mode: 1
            });
        } else if (type == 3) {
            const fIndex = datasetclone.findIndex(x => x.id == data.id);
            datasetclone[fIndex].tb1.name = data.title;
            datasetclone[fIndex].tb2.name = data.description;
            setdataset(datasetclone);
            setdialogstate({ ...dialogstate, state: false });
            setresponseobj({
                state: true,
                message: 'Successfully updated',
                mode: 1
            });
        } else {
            setresponseobj({
                state: true,
                message: 'Some problem occured',
                mode: 0
            })
        }
        setselectedContent([])
    }

    useEffect(() => {
        // handleGetOnboardingInfo(selectedtype);
    }, [selectedtype]);




    return (
        <div className="mytmg-container">

            <div className="p-5">
                {responseobj.state && <Response mode={responseobj.mode} message={responseobj.message} />}
                <div className="pt-3" />
                <Header />
                <div className="pt-5 pr-4">
                    <div className="row m-0">
                        <h3 className="primary-colored flexfull">Content</h3>
                        <select className="mr-2 selectinput" onChange={e => setselectedtype(e.target.value)}>
                            <option value={0}>Onboarding Section</option>
                            <option value={1}>Education Section</option>
                        </select>
                        <Button
                            onClick={() => setdialogstate({
                                ...dialogstate,
                                state: true
                            })}
                            className="header-btn">
                            <img src={Icons.WHITEPLUSICON} />
                            <h4>Create new content</h4>
                        </Button>
                    </div>

                </div>
            </div>
            <Dialog.Content
                type={selectedtype}
                dataInfo={selectedContent}
                state={dialogstate.state}
                onSave={(type, data) => handleSave(type, data)} onClose={() => {
                    setselectedContent([]);
                    setTimeout(() => {
                        setdialogstate({ ...dialogstate, state: false });
                    }, 500);
                }} />
        </div >
    );
}

const ContentInfoScreen = HOC(ContentInfo, 8);

export default ContentInfoScreen;